import { toast } from 'react-toastify'
import * as constants from '../utils/constants'
import Method from '../utils/methods'
import { String, Error } from '../utils/string'
import GlobalValidations from '../utils/validations'

import axios from 'axios'

class APICallService {
  constructor(apiname, params) {
    this.url = constants.BASE_URL
    if (apiname.constructor === Array) {
      this.apiType = []
      this.apiName = []
      this.params = []
      apiname.forEach((item, index) => {
        var arr = item.toString().split(' ')
        this.apiType[index] = arr[1]
        this.apiName[index] = arr[0]
        this.params[index] = params[index]
      })
    } else {
      var arr = apiname.toString().split(' ')
      this.apiType = arr[1]
      this.apiName = arr[0]
      this.params = params
    }
    this.listApi = [constants.LOGIN]
  }

  async findSettings(apiName, apiType, params) {
    const resourceURL = `${this.url}${apiName}`
    var myHeaders = {}
    try {
      var mainAPIName = apiName + ' ' + apiType
      let token = await Method.getPref(constants.PREF_TOKEN)

      if (!this.listApi.includes(mainAPIName)) {
        myHeaders = { ...myHeaders, Authorization: 'Bearer ' + token }
      }
    } catch (error) {
      console.log(error, 'PREF_TOKEN error')
    }

    myHeaders = { ...myHeaders, platform: 'web' }
    myHeaders = { ...myHeaders, appVersion: '1.0' }

    console.log('myHeaders', myHeaders)
    var settings = {
      redirect: 'follow',
      url: resourceURL,
      headers: myHeaders,
    }
    switch (apiType) {
      case constants.GET:
        settings.method = 'GET'
        break
      case constants.GET_ID_PARAMS:
        settings.method = 'GET'
        settings.url = resourceURL + '/' + params
        break
      case constants.GET_URL_PARAMS:
        settings.method = 'GET'
        settings.url = resourceURL + '?' + this.objToQueryString(params)
        break
      case constants.GET_URL_ENCODED:
        myHeaders = {
          ...myHeaders,
          'Content-Type': 'application/x-www-form-urlencoded',
        }

        settings.headers = myHeaders
        settings.method = 'GET'
        settings.data = this.objToURLEncodedString(params)
        break
      case constants.POST:
        settings.headers = myHeaders

        settings.method = 'POST'
        break
      case constants.POST_RAW:
        myHeaders = {
          ...myHeaders,
          'Content-Type': 'application/json',
        }
        settings.headers = myHeaders
        settings.method = 'POST'
        settings.data = JSON.stringify(params)
        break
      case constants.POST_FORM:
        settings.headers = myHeaders
        settings.method = 'POST'
        settings.data = params
        break
      case constants.POST_URL_PARAMS:
        myHeaders = {
          ...myHeaders,
          'Content-Type': 'application/json',
        }
        settings.method = 'POST'
        if (params.page) {
          settings.url =
            resourceURL +
            '?' +
            this.objToQueryString({
              pageNo: params.page,
              perPage: params.perPage,
              searchTerm: params.searchTerm ? params.searchTerm : '',
            })
        }
        if (params.page) {
          delete params.page
          delete params.perPage

          settings.data = params
        }
        break
      case constants.POST_URL_ENCODED:
        myHeaders = {
          ...myHeaders,
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          Accept: 'application/json',
        }
        settings.headers = myHeaders
        settings.method = 'POST'
        settings.data = this.objToURLEncodedString(params)
        break
      case constants.PATCH_FORM:
        settings.headers = myHeaders
        settings.method = 'PATCH'
        settings.data = this.objToFormData(params)
        break
      case constants.PATCH_URL_ENCODED:
        myHeaders = {
          ...myHeaders,
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          Accept: 'application/json',
        }
        settings.headers = myHeaders
        settings.method = 'PATCH'
        settings.data = this.objToURLEncodedString(params)
        break
      case constants.DELETE:
        myHeaders = {
          ...myHeaders,
          'Content-Type': 'application/json',
        }
        settings.headers = myHeaders
        settings.method = 'DELETE'
        settings.data = JSON.stringify(params)
        break
      case constants.DELETE_URL_PARAMS:
        settings.method = 'DELETE'
        settings.url = resourceURL + '?' + this.objToQueryString(params)
        break
      case constants.DELETE_URL_ENCODED:
        myHeaders = {
          ...myHeaders,
          'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
          Accept: 'application/json',
        }
        settings.headers = myHeaders
        settings.method = 'DELETE'
        settings.data = this.objToURLEncodedString(params)
        break
      case constants.MULTI_PART_POST:
        myHeaders = {
          ...myHeaders,
          'Content-Type': 'multipart/form-data',
          Accept: '*/*',
        }
        settings.headers = myHeaders
        settings.method = 'POST'
        settings.data = params
        break
      case constants.MULTI_PART_PATCH:
        settings.headers = myHeaders
        settings.method = 'PATCH'
        settings.data = params
        break
      default:
        settings.method = 'GET'
        break
    }
    return settings
  }

  objToQueryString = (obj) => {
    const keyValuePairs = []
    for (const key in obj) {
      keyValuePairs.push(key + '=' + obj[key])
    }
    return keyValuePairs.join('&')
  }

  objToURLEncodedString = (obj) => {
    var formdata = []
    for (var property in obj) {
      var encodedKey = encodeURIComponent(property)
      var encodedValue = encodeURIComponent(obj[property])
      if (obj[property].constructor === Array) {
        obj[property].forEach(
          // eslint-disable-next-line
          (obj) => formdata.push(encodedKey + '=' + encodeURIComponent(obj)),
        )
      } else formdata.push(encodedKey + '=' + encodedValue)
    }
    formdata = formdata.join('&')
    return formdata
  }

  objToFormData = (obj) => {
    const form = new FormData()
    for (const key in obj) {
      form.append(key, obj[key])
    }
    return form
  }

  async callAPI() {
    const mObj = await GlobalValidations.checkNetConnection()
    if (!mObj) {
      toast.error(Error.NET_ERR)
      return { code: 899, message: Error.NET_ERR }
    } else {
      this.settings = await this.findSettings(
        this.apiName,
        this.apiType,
        this.params,
      )
      console.log('this.settings', this.settings)
      console.log('URL=> ' + JSON.stringify(this.settings.url))
      console.log('ApiType=> ' + this.apiType)
      console.log('Header=> ' + JSON.stringify(this.settings.headers))
      console.log('Params=> ' + this.settings.data)
      return axios(this.settings.url, this.settings)
        .then(async (res) => {
          if (res.data.status === constants.ResponseSuccess) {
            return res.data.result
          } else if (res.data.status === constants.Not_found) {
            return 0
          } else if (res.data.status === constants.ResponseFail) {
            toast.error(res.data.message)
            return 0
          } else if (res.data.status === constants.Server_error) {
            // window.location =
            //   window.location.protocol +
            //   '//' +
            //   window.location.host +
            //   '/error/internalServer'
          } else if (res.data.status === constants.Maintenance) {
            window.location =
              window.location.protocol +
              '//' +
              window.location.host +
              '/error/internalServer'
          } else if (res.data.status === constants.MissingToken) {
            localStorage.removeItem('bearerToken')
            localStorage.removeItem('user')
            localStorage.removeItem('location')
            localStorage.removeItem('isShow')
            localStorage.removeItem('section')
            window.location =
              window.location.protocol +
              '//' +
              window.location.host +
              '/auth/login'
          } else if (res.data.status === constants.AuthError) {
            toast.error(res.data.message)

            // if (window.location.pathname === '/auth/login') {
            //   toast.error(res.data.message)
            //   return 0
            // } else {
            //   localStorage.removeItem('bearerToken')
            //   localStorage.removeItem('user')
            //   window.location =
            //     window.location.protocol +
            //     '//' +
            //     window.location.host +
            //     '/auth/login'
            // }
          }
        })
        .catch((err) => {
          // localStorage.removeItem('bearerToken')
          // localStorage.removeItem('user')
          // window.location =
          //   window.location.protocol +
          //   '//' +
          //   window.location.host +
          //   '/auth/login'
        })
    }
  }
}

export default APICallService
