import React, { useMemo } from 'react'
import { QuickUserToggler } from '../extras/QuiclUserToggler'
import { useHtmlClassService } from '../../_core/AkhieLayoutProvider'
import objectPath from 'object-path'
export function Topbar() {
  const uiService = useHtmlClassService()
  const layoutProps = useMemo(() => {
    return {
      viewUserDisplay: objectPath.get(uiService.config, 'extras.user.display'),
    }
  }, [uiService])
  return (
    <div className="topbar">
      {layoutProps.viewUserDisplay && <QuickUserToggler />}
    </div>
  )
}
