/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from 'react'
import { useLocation } from 'react-router'
import { NavLink } from 'react-router-dom'
import SVG from 'react-inlinesvg'
import { toAbsoluteUrl, checkIsActive } from '../../../../_helpers'

export function AsideMenuList({ layoutProps }) {
  const location = useLocation()
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && 'menu-item-active'} menu-item-open `
      : ''
  }

  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/*begin::1 Level*/}
        <li
          className={`menu-item ${getMenuItemActive('/dashboard', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/dashboard">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Design/Layers.svg')} />
            </span>
            <span className="menu-text">Dashboard</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive('/cuisine', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/cuisine">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  '/media/svg/icons/Cooking/KnifeAndFork1.svg',
                )}
              />
            </span>
            <span className="menu-text">Cuisine</span>
          </NavLink>
        </li>

        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/users',
            true,
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link " to="/users">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  '/media/svg/icons/Communication/Add-user.svg',
                )}
              />
            </span>
            <span className="menu-text">Users</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive('/events', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/events">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  '/media/svg/icons/Cooking/KnifeAndFork2.svg',
                )}
              />
            </span>
            <span className="menu-text">Events</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive('/transactions', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/transactions">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/Shopping/Wallet2.svg')}
              />
            </span>
            <span className="menu-text">Transactions</span>
          </NavLink>
        </li>
        {/* <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/reservation',
            true,
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link " to="/reservation">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  '/media/svg/icons/Communication/Add-user.svg',
                )}
              />
            </span>
            <span className="menu-text">Reservations</span>
          </NavLink>
        </li> */}
        <li
          className={`menu-item ${getMenuItemActive('/inquiry', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/inquiry">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/Communication/Call.svg')}
              />
            </span>
            <span className="menu-text">Inquiry</span>
          </NavLink>
        </li>
        <li
          className={`menu-item ${getMenuItemActive('/revenue', false)}`}
          aria-haspopup="true"
        >
          <NavLink className="menu-link" to="/revenue">
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl('/media/svg/icons/Shopping/Dollar.svg')}
              />
            </span>
            <span className="menu-text">Revenue</span>
          </NavLink>
        </li>
        <li
          className={`menu-item menu-item-submenu ${getMenuItemActive(
            '/settings',
            true,
          )}`}
          aria-haspopup="true"
          data-menu-toggle="hover"
        >
          <NavLink className="menu-link menu-toggle" to="/settings">
            <span className="svg-icon menu-icon">
              <SVG src={toAbsoluteUrl('/media/svg/icons/Code/Settings4.svg')} />
            </span>
            <span className="menu-text">Settings</span>
            <i className="menu-arrow" />
          </NavLink>
          <div className="menu-submenu ">
            <i className="menu-arrow" />
            <ul className="menu-subnav">
              <li className="menu-item  menu-item-parent" aria-haspopup="true">
                <span className="menu-link">
                  <span className="menu-text">Settings</span>
                </span>
              </li>

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  '/settings/app-settings',
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/settings/app-settings">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">App Settings</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}

              {/*begin::2 Level*/}
              <li
                className={`menu-item ${getMenuItemActive(
                  '/settings/about-us',
                )}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/settings/about-us">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">About Us</span>
                </NavLink>
              </li>
              <li
                className={`menu-item ${getMenuItemActive('/settings/policy')}`}
                aria-haspopup="true"
              >
                <NavLink className="menu-link" to="/settings/policy">
                  <i className="menu-bullet menu-bullet-dot">
                    <span />
                  </i>
                  <span className="menu-text">Privacy Policy</span>
                </NavLink>
              </li>
              {/*end::2 Level*/}
            </ul>
          </div>
        </li>
      </ul>

      {/* end::Menu Nav */}
    </>
  )
}
