import React, { useEffect, useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import { Link, Redirect, useLocation } from 'react-router-dom'
import { ReactComponent as Logo } from '../../../../_akhie_frontend/_assets/Logo.svg'
import APICallService from '../../../../api/apiCallService'
import apiJSON from '../../../../api/apiJSON'
import { RESETPASSWORD } from '../../../../utils/constants'
import { toast } from 'react-toastify'
import { String } from '../../../../utils/string'
const ResetPassword = () => {
  // const passwordTest = /^(?=.*\d)(?=.*[A-Z]).{8,16}$/i;
  const search = useLocation().search

  const [loading, setLoading] = useState(false)
  const [redirect, setRedirect] = useState(false)
  const [email, setEmail] = useState('info@akhie.app')
  const [code, setCode] = useState(
    localStorage.getItem('code') ? localStorage.getItem('code') : '',
  )
  useEffect(() => {
    ;(async () => {
      const email = new URLSearchParams(search).get('email')
      let code = new URLSearchParams(search).get('token')
      await setEmail(email)
      await setCode(code)
    })()
  }, [])
  const handleSubmit = async (data) => {
    setLoading(true)
    let apiService = new APICallService(
      RESETPASSWORD,
      apiJSON.ResetPassword(email, data.password, code),
    )
    let response = await apiService.callAPI()

    localStorage.removeItem('code')
    toast.success(String.messages.passwordReset)

    setLoading(false)
    setRedirect(true)
  }
  return (
    <>
      {redirect === false ? (
        email && code ? (
          <div className="col-lg-4 bg-white mt-10" id="kt_login_signin_form">
            <div className="mt-5 text-center">
              {/* <h3 className="font-size-h1 font-weight-bolder text-left text-54">
							<FormattedMessage id="AUTH.LOGIN.TITLE" />
						</h3> */}
              <Logo style={{ stroke: '#6959FC', height: '150px' }} />
            </div>
            <div className="mt-4 mb-3 d-flex justify-content-end border-top border-#ebedf3"></div>
            <div className="text-center mb-10 mb-lg-6">
              <h3 className="font-size-h1 font-weight-bolder text-center text-54">
                {String.auth.resetPassword}
              </h3>
              <p className="text-54 opacity9 font-weight-bold text-muted text-center f-16">
                {String.auth.verified}
              </p>
            </div>

            <Formik
              initialValues={{ password: '', confirmPassword: '' }}
              validate={(values) => {
                let errors = {}

                if (Boolean(values?.password.trim()) === false)
                  errors.password = 'Password is required!'
                else if (values.password.length < 8)
                  errors.password = 'Password must be 8 characters at minimum!'
                // else if (!passwordTest.test(values.password))
                //   errors.password =
                //     "Your password must be between 8 to 16 characters long and contain at least 1 number and 1 capital letter!";

                if (Boolean(values?.confirmPassword.trim()) === false)
                  errors.confirmPassword = 'Confirm Password is required!'
                else if (values.confirmPassword.length < 8)
                  errors.confirmPassword =
                    'Confirm Password must be 8 characters at minimum!'
                // else if (!passwordTest.test(values.confirmPassword))
                //   errors.confirmPassword =
                //     "Your Confirm Password must be between 8 to 16 characters long and contain at least 1 number and 1 capital letter!";
                if (
                  values?.password.localeCompare(values?.confirmPassword) !== 0
                ) {
                  errors.confirmPassword =
                    'Confirm Password must be same as password!'
                }
                return errors
              }}
              onSubmit={(values, { setSubmitting }) => {
                setLoading(true)
                // this.props.loginUser(values);
                handleSubmit(values)
                setSubmitting(false)
              }}
            >
              {({ touched, errors, isSubmitting }) => (
                <Form className="form fv-plugins-bootstrap fv-plugins-framework">
                  <div className="form-group fv-plugins-icon-container">
                    <Field
                      placeholder={String.auth.newPassword}
                      type="password"
                      className={`form-control form-control-solid h-auto py-5 px-6 ${
                        touched.password
                          ? errors.password
                            ? 'is-invalid'
                            : 'is-valid'
                          : ''
                      }`}
                      name="password"
                    />
                    <ErrorMessage
                      component="div"
                      name="password"
                      className="invalid-feedback"
                    />
                  </div>

                  <div className="form-group fv-plugins-icon-container">
                    <Field
                      placeholder={String.auth.confirmNewPassword}
                      type="password"
                      className={`form-control form-control-solid h-auto py-5 px-6 ${
                        touched.confirmPassword
                          ? errors.confirmPassword
                            ? 'is-invalid'
                            : 'is-valid'
                          : ''
                      }`}
                      name="confirmPassword"
                    />
                    <ErrorMessage
                      component="div"
                      name="confirmPassword"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="text-center">
                    <button
                      type="submit"
                      disabled={loading}
                      className={`btn btn-primary font-weight-bold px-9 py-3 my-3`}
                    >
                      <span className="f-17">{String.auth.resetPassword}</span>
                      {loading && (
                        <span className="ml-3 spinner spinner-white"></span>
                      )}
                    </button>
                  </div>
                  <div className="text-center mb-2">
                    <Link
                      to="/auth/login"
                      className="text-54 opacity9 font-weight-bold f-16 text-hover-primary my-3 mr-2"
                      id="kt_login_forgot"
                    >
                      {String.auth.backToLogin}
                    </Link>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        ) : (
          <Redirect to="/auth/login" />
        )
      ) : (
        <Redirect to="/auth/login" />
      )}
    </>
  )
}
export default ResetPassword
