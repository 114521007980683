/**
 * Entry application component used to compose providers and render Routes.
 * */

import React from 'react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'

import Routes from '../app/Routes'
import { I18nProvider } from '../_akhie_frontend/i18n'
import {
  LayoutSplashScreen,
  AkhieThemeProvider,
} from '../_akhie_frontend/layout'
import store from '../app/store/store'

import { createBrowserHistory } from 'history'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { setCurrentUser, setResetCode } from '../app/actions/authActions'

if (localStorage.user) {
  // Set auth token header auth
  // const token = localStorage.bearerToken
  // setAuthToken(JSON.parse(token))
  // Set user and isAuthenticated
  const user = JSON.parse(localStorage.getItem('user'))
  store.dispatch(setCurrentUser(user))
}
if (localStorage.code) {
  store.dispatch(setResetCode(localStorage.getItem('code')))
}

const history = createBrowserHistory({
  //pass a configuration object here if needed
  forceRefresh: true,
})

const App = (props) => {
  return (
    /* Provide Redux store */
    <Provider store={store}>
      <React.Suspense fallback={<LayoutSplashScreen />}>
        {/* Override `basename` (e.g: `homepage` in `package.json`) */}
        <BrowserRouter basename={props.basename}>
          {/*This library only returns the location that has been active before the recent location change in the current window lifetime.*/}
          <AkhieThemeProvider>
            {/* Provide `react-intl` context synchronized with Redux state.  */}
            <I18nProvider>
              <ToastContainer />

              {/* Render routes with provided `Layout`. */}

              <Routes />
            </I18nProvider>
          </AkhieThemeProvider>
        </BrowserRouter>
      </React.Suspense>
    </Provider>
  )
}
export default App
