import React, { useState } from 'react'
import clsx from 'clsx'
import { Button, Modal } from 'react-bootstrap'
import { String } from '../../utils/string'
import { DELETECUISINE } from '../../utils/constants'
import APICallService from '../../api/apiCallService'
import apiJSON from '../../api/apiJSON'
import { toast } from 'react-toastify'
const DeleteCuisine = (props) => {
  const [show, setShow] = useState(false)

  const [loading, setLoading] = useState(false)
  const handleDelete = async () => {
    setLoading(true)
    let apiService = new APICallService(
      DELETECUISINE,
      apiJSON.DeleteCuisine(props._id),
    )
    let response = await apiService.callAPI()

    toast.success(String.messages.cuisineDeleted)

    props.reload()
    setShow(false)
    setLoading(false)
  }
  return (
    <>
      <i
        className={clsx(
          'flaticon2-rubbish-bin-delete-button icon-lg deleteIcon',
        )}
        onClick={() => {
          setShow(!show)
        }}
      ></i>
      <Modal
        show={show}
        onHide={() => {
          setShow(!show)
        }}
        size="md"
        centered
      >
        <Modal.Body>
          <div className="text-center my-3">
            <div className="mb-3">
              <p className="font-weight-bold delete-font">
                {String.general.remove} {String.cuisine.cuisine}
              </p>
              <p className="text-delete">
                {String.general.sure} {String.cuisine.cuisine}?<br></br>{' '}
                {String.general.recover}
              </p>
            </div>
            <div className="mb-3">
              <Button
                variant="danger"
                onClick={handleDelete}
                disabled={loading}
                className="btn-delete"
              >
                <span className="f-17">Yes</span>
                {loading && (
                  <span className="ml-3 spinner spinner-white mr-3"></span>
                )}
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  setShow(!show)
                }}
                className="btn-delete"
              >
                No
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default DeleteCuisine
