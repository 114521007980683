import React, { Suspense } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import {
  LayoutSplashScreen,
  ContentRoute,
} from '../../../_akhie_frontend/layout'
import Dashboard from './Dashboard'

export default function cuisine() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {<Redirect exact={true} from="/" to="/dashboard" />}
        <ContentRoute path="/dashboard" component={Dashboard} />
      </Switch>
    </Suspense>
  )
}
