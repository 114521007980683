import React, { useEffect, useState } from 'react'
import { String } from '../../../utils/string'
import APICallService from '../../../api/apiCallService'
import apiJSON from '../../../api/apiJSON'
import { EVENTDETAILS } from '../../../utils/constants'
import { Image, Modal, Spinner } from 'react-bootstrap'
import { connect } from 'react-redux'
import moment from 'moment'
import LightBox from '../Global/LightBox'
import Carousel from '../Global/Carousel'
const EventDetails = (props) => {
  const [fetchLoader, setFetchLoader] = useState(false)
  const [eventData, setEventData] = useState(true)
  const [show, setShow] = useState(false)
  const [showLightBox, setShowLightBox] = useState(false)
  const [images, setImages] = useState('')
  const [lightImages, setLightImages] = useState('')
  useEffect(() => {
    ; (async () => {
      setFetchLoader(true)
      await fetchData()
      setFetchLoader(false)
    })()
  }, [])
  const fetchData = async () => {
    setFetchLoader(true)
    let apiService = new APICallService(
      EVENTDETAILS,
      apiJSON.eventDetails(props.match.params.id),
    )
    let response = await apiService.callAPI()
    if (response) {
      setEventData(response)
    }
  }
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-custom gutter-b example example-compact">
            <div className="card-header row">
              <div className="card-title col-lg">
                <h3 className="card-label">{String.events.details}</h3>
              </div>
              <div className="separator separator-dashed my-7"></div>
            </div>
            <div className="card-body">
              {fetchLoader ? (
                <>
                  <div className="card-body">
                    <div className="d-flex justify-content-center">
                      <Spinner
                        animation="border"
                        variant="primary"
                        style={{ height: '4em', width: '4em' }}
                      />
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col-lg-12 d-lg-flex pl-0">
                      <div className="col-lg-9">
                        <p>
                          <span className="font-weight-bold fs-14">
                            {String.events.eventName} :-{' '}
                          </span>
                          <span>{eventData.name}</span>
                        </p>
                        <p>
                          {' '}
                          <span className="font-weight-bold fs-14">
                            {String.events.location} :-{' '}
                          </span>
                          <span>
                            {eventData.location
                              ? eventData.location.address
                              : ''}
                          </span>
                        </p>
                        <p>
                          {' '}
                          <span className="font-weight-bold fs-14">
                            {String.events.price} :-{' '}
                          </span>
                          <span>
                            € {eventData.price ? eventData.price.toFixed(2) : ""}/{String.events.person}
                          </span>
                        </p>
                      </div>
                      <div className="col-lg-3">
                        <Image
                          src={eventData.thumbnail}
                          rounded
                          thumbnail={true}
                          onClick={(e) => {
                            setLightImages(eventData.image)
                            setShowLightBox(true)
                          }}
                        />
                      </div>
                      {showLightBox ? (
                        <LightBox
                          images={lightImages}
                          onHide={() => {
                            setShowLightBox(false)
                          }}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="separator separator-dashed my-2"></div>
                  <div className="row">
                    <div className="col-lg-12 d-lg-flex pl-0">
                      <div className="col-lg-6">
                        <p>
                          <span className="font-weight-bold fs-14">
                            {String.events.eventDate} :-{' '}
                          </span>
                          <span>
                            {moment
                              .utc(eventData.eventDate)
                              .format('DD/MM/YYYY')}
                          </span>
                        </p>
                        <p>
                          <span className="font-weight-bold fs-14">
                            {String.events.eventStartTime} :-{' '}
                          </span>
                          <span>
                            {moment.unix(eventData.eventStartTime).utc().local().format('HH:mm')}
                          </span>
                        </p>
                        <p>
                          <span className="font-weight-bold fs-14">
                            {String.events.eventEndTime} :-{' '}
                          </span>
                          <span>
                            {moment.unix(eventData.eventEndTime).utc().local().format('HH:mm')}
                          </span>
                        </p>
                        <p>
                          {' '}
                          <span className="font-weight-bold fs-14">
                            {String.cuisine.cuisine} :-{' '}
                          </span>
                          <span>{eventData.cuisine}</span>
                        </p>
                        {eventData.type ? (
                          eventData.type.includes(0) &&
                            eventData.type.includes(1) ? (
                            <>
                              <p>
                                <span className="font-weight-bold fs-14">
                                  {String.events.dineIn} :-{' '}
                                </span>
                                <span>{eventData.maxGuest}</span>
                              </p>
                              <p>
                                <span className="font-weight-bold fs-14">
                                  {String.events.takeaway} :-{' '}
                                </span>
                                <span>{eventData.maxTakeAway}</span>
                              </p>
                            </>
                          ) : eventData.type.includes(0) ? (
                            <p>
                              <span className="font-weight-bold fs-14">
                                {String.events.maxGuest} :-{' '}
                              </span>
                              <span>{eventData.maxGuest}</span>
                            </p>
                          ) : (
                            <p>
                              <span className="font-weight-bold fs-14">
                                {String.events.takeaway} :-{' '}
                              </span>
                              <span>{eventData.maxTakeAway}</span>
                            </p>
                          )
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-2"></div>
                  <div className="row">
                    <div className="col-lg-12 pl-0">
                      <div className="col-lg-12">
                        <p>
                          <span className="font-weight-bold fs-14">
                            {String.events.hostName} :-{' '}
                          </span>
                          <span>
                            {eventData.users ? eventData.users.name : ''}
                          </span>
                        </p>
                      </div>
                      {eventData.users ? (
                        eventData.users.averageReview ? (
                          <div className="col-lg-12">
                            <p>
                              <span className="font-weight-bold fs-14">
                                {String.events.averageReview} :-{' '}
                              </span>
                              <span>
                                {eventData.users
                                  ? eventData.users.averageReview
                                    ? eventData.users.averageReview
                                    : ''
                                  : ''}
                              </span>
                            </p>
                          </div>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                      {eventData.users ? (
                        eventData.users.totalReview ? (
                          <div className="col-lg-12">
                            <p>
                              <span className="font-weight-bold fs-14">
                                {String.events.totalReview} :-{' '}
                              </span>
                              <span>
                                {eventData.users
                                  ? eventData.users.totalReview
                                    ? eventData.users.totalReview
                                    : ''
                                  : ''}
                              </span>
                            </p>
                          </div>
                        ) : (
                          ''
                        )
                      ) : (
                        ''
                      )}
                    </div>
                  </div>
                  <div className="separator separator-dashed my-2"></div>
                  <div className="row">
                    <div className="col-lg-12 pl-0">
                      <div className="col-lg-12">
                        <p>
                          {' '}
                          <span className="font-weight-bold fs-14">
                            {String.events.foodText} :-{' '}
                          </span>
                          {/* <span>{eventData.name}</span> */}
                        </p>
                      </div>
                      {show ? (
                        <Carousel
                          open={show}
                          images={images}
                          close={() => {
                            setShow(!show)
                          }}
                        />
                      ) : (
                        <></>
                      )}
                      <div className="col-lg-12 d-flex pl-0">
                        {eventData.item
                          ? eventData.item.map((itemVal) => {
                            return (
                              <>
                                <div className="col-lg-3 ">
                                  <img
                                    src={itemVal.images[0].thumbnail}
                                    onClick={(e) => {
                                      setImages(itemVal)
                                      setShow(true)
                                    }}
                                  />
                                </div>
                              </>
                            )
                          })
                          : ''}
                      </div>
                      <div className="col-lg-12 d-flex pl-0 ">
                        {eventData.item
                          ? eventData.item.map((itemVal) => {
                            return (
                              <>
                                <div className="col-lg-3">
                                  <span className="font-weight-bold fs-14">
                                    {itemVal.name}
                                  </span>
                                </div>
                              </>
                            )
                          })
                          : ''}
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-2"></div>
                  <div className="row">
                    <div className="col-lg-12 d-lg-flex pl-0">
                      <div className="col-lg-9">
                        <span className="font-weight-bold fs-14">
                          {String.events.allergies} :-{' '}
                        </span>
                        <span>{eventData.allergies}</span>
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-2"></div>
                  <div className="row">
                    <div className="col-lg-12 d-lg-flex pl-0">
                      <div className="col-lg-9">
                        <span className="font-weight-bold fs-14">
                          {String.events.rules} :-{' '}
                        </span>
                        <span>{eventData.rules}</span>
                      </div>
                    </div>
                  </div>
                  <div className="separator separator-dashed my-2"></div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})
export default connect(mapStateToProps)(EventDetails)
