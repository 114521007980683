/**
 * Create React App entry point. This and `public/index.html` files can not be
 * changed or moved.
 */
import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'
import React from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'

import App from './app/App'
import './index.scss' // Standard version
// import "./sass/style.react.rtl.css"; // RTL version
import './_akhie_frontend/_assets/plugins/keenthemes-icons/font/ki.css'
import 'socicon/css/socicon.css'
import '@fortawesome/fontawesome-free/css/all.min.css'
import './_akhie_frontend/_assets/plugins/flaticon/flaticon.css'
import './_akhie_frontend/_assets/plugins/flaticon2/flaticon.css'
// Datepicker
import 'react-datepicker/dist/react-datepicker.css'
import {
  AkhieLayoutProvider,
  AkhieSplashScreenProvider,
  AkhieSubheaderProvider,
} from './_akhie_frontend/layout'
import { AkhieI18nProvider } from './_akhie_frontend/i18n'

const { PUBLIC_URL } = process.env

// _redux.setupAxios(axios, store)

ReactDOM.render(
  <AkhieI18nProvider>
    <AkhieLayoutProvider>
      <AkhieSubheaderProvider>
        <AkhieSplashScreenProvider>
          <App basename={PUBLIC_URL} />
        </AkhieSplashScreenProvider>
      </AkhieSubheaderProvider>
    </AkhieLayoutProvider>
  </AkhieI18nProvider>,
  document.getElementById('root'),
)
