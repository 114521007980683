import { Formik, Form, ErrorMessage } from 'formik'
import React, { useState } from 'react'
import { connect } from 'react-redux'
import APICallService from '../../../api/apiCallService'
import apiJSON from '../../../api/apiJSON'
import { ADDCUISINE } from '../../../utils/constants'

import {
  Collapse,
  FormControlLabel,
  Switch,
  TextField,
  ThemeProvider,
  createTheme,
  Button,
} from '@mui/material'
import { String } from '../../../utils/string'
import { toast } from 'react-toastify'
const AddCuisine = (props) => {
  const theme7 = createTheme({
    palette: {
      primary: { main: '#6959FC' },
    },
  })
  const initialState = {
    name: '',
  }
  const [checked, setChecked] = useState(true)
  const [input, setInput] = useState(initialState)

  const [loading, setLoading] = useState(false)
  const handleAdd = async () => {
    setLoading(true)
    let apiService = new APICallService(
      ADDCUISINE,
      apiJSON.AddCuisine(input.name),
    )
    let response = await apiService.callAPI()

    setInput({ name: '' })
    toast.success(String.messages.cuisineAdded)
    // setChecked(!checked)
    props.reload()
    setLoading(false)
  }
  const handleChange = (event, name) => {
    let data = { ...input }
    data[name] = event.target.value
    setInput(data)
  }
  const handleOnKeyPress = (event) => {
    var charCode = event.which ? event.which : event.keyCode
    if (
      !(charCode >= 65 && charCode <= 123) &&
      charCode !== 32 &&
      charCode !== 0
    ) {
      event.preventDefault()
      return false
    }
    return true
  }
  return (
    <>
      <div>
        <Formik
          enableReinitialize={initialState}
          initialValues={input}
          onSubmit={handleAdd}
          validate={(values) => {
            let errors = {}
            if (Boolean(values?.name.trim()) === false) errors.name = '*'

            return errors
          }}
        >
          {({ touched, errors }) => (
            <div className="row">
              <div className="col-md-12">
                <div className="card card-custom gutter-b example example-compact">
                  <div className="card-header row">
                    <div className="card-title col-lg-auto">
                      <h3 className="card-label">{String.cuisine.addTitle}</h3>
                    </div>
                    <div className="col-lg-auto align-self-center d-flex">
                      <div>
                        <FormControlLabel
                          control={
                            <Switch
                              color="secondary"
                              checked={checked}
                              onChange={() => {
                                setChecked(!checked)
                              }}
                            />
                          }
                          label="Show"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <Collapse in={checked}>
                      <div className="card-body py-2">
                        <div className="row">
                          <div className="col-lg-12 mt-3">
                            <Form>
                              <div className="row">
                                <div className="col-lg-12 d-block  float-left">
                                  <div className="row d-block ">
                                    <div className="col-lg-12 d-flex">
                                      <div
                                        className={
                                          'd-flex flex-wrap float-left ml-n2'
                                        }
                                      >
                                        <p className="text-54 f-15 mb-2 font-weight-bold ml-3">
                                          {String.cuisine.cuisineName}
                                        </p>
                                      </div>
                                      <div
                                        className={
                                          'd-flex flex-wrap float-left ml-n6'
                                        }
                                      >
                                        <ErrorMessage
                                          component="div"
                                          name="name"
                                          className="invalid-star"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row ">
                                <div className="col-lg-6">
                                  <div>
                                    <ThemeProvider theme={theme7}>
                                      <TextField
                                        error={
                                          Object.keys(touched).length &&
                                          Object.keys(errors).length
                                        }
                                        sx={{
                                          '.Mui-error ': {
                                            borderColor: ' #f64e60',
                                          },

                                          '& .MuiOutlinedInput-root': {
                                            '& fieldset': {
                                              borderWidth: '1px !important',
                                            },
                                          },
                                          '& .Mui-error.MuiOutlinedInput-root': {
                                            '& fieldset': {
                                              borderColor: '#f64e60',
                                            },
                                            '&:hover fieldset': {
                                              borderColor: '#f64e60',
                                            },
                                            '&.Mui-focused fieldset': {
                                              borderColor: '#f64e60',
                                            },
                                          },
                                        }}
                                        className="bw"
                                        label=""
                                        //  MuiInputBase-root MuiOutlinedInput-root MuiInputBase-colorPrimary Mui-error MuiInputBase-fullWidth MuiInputBase-formControl css-byus0x-MuiInputBase-root-MuiOutlinedInput-root
                                        variant="outlined"
                                        id="mui-theme-provider-outlined-input"
                                        fullWidth
                                        name="name"
                                        value={input.name}
                                        onChange={(e) => {
                                          handleChange(e, 'name')
                                        }}
                                        onKeyPress={(e) => {
                                          handleOnKeyPress(e, 'name')
                                        }}
                                      />
                                    </ThemeProvider>
                                  </div>
                                </div>
                              </div>
                              <div className="row  mt-2">
                                <div>
                                  <Button
                                    type="submit"
                                    variant="contained"
                                    className={` btn btn-primary font-weight-bold px-9 py-3 my-3 ml-4`}
                                  >
                                    {String.general.add}
                                    {loading && (
                                      <span className="spinner spinner-white"></span>
                                    )}
                                  </Button>
                                </div>
                              </div>
                            </Form>
                          </div>
                        </div>
                      </div>
                    </Collapse>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Formik>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})

export default connect(mapStateToProps)(AddCuisine)
