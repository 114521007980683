import React, { useState } from 'react'
/*eslint no-restricted-imports: ["error", { "paths": ["cluster"] }]*/
import { Button, Modal } from 'react-bootstrap'
import { ErrorMessage, Form, Formik } from 'formik'
import { createTheme, TextField, ThemeProvider } from '@mui/material'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { logoutUser } from '../../app/actions/authActions'
import { CHANGEPASSWORD } from '../../utils/constants'
import APICallService from '../../api/apiCallService'
import apiJSON from '../../api/apiJSON'
import { String } from '../../utils/string'
const ChangePassword = (props) => {
  const theme7 = createTheme({
    palette: {
      primary: { main: '#6959FC' },
    },
  })
  const [show, setShow] = useState(props.show)
  const handleClose = () => setShow(props.onHide)
  const [loading, setLoading] = useState(false)
  const height = 10
  const initialState = {
    currentPassword: '',
    password: '',
    confirmPassword: '',
  }
  // const passwordTest = /^(?=.*\d)(?=.*[A-Z]).{8,16}$/i;
  const [input, setInput] = useState(initialState)

  const handleChangePassword = async () => {
    setLoading(true)
    let apiService = new APICallService(
      CHANGEPASSWORD,
      apiJSON.ChangePassword(input.currentPassword, input.password),
    )
    let response = await apiService.callAPI()

    setInput({ currentPassword: '', password: '', confirmPassword: '' })
    toast.success(String.messages.passwordUpdate)

    setLoading(false)
    props.onHide()
  }

  const handleChange = (event, name) => {
    let data = { ...input }
    data[name] = event.target.value
    setInput(data)
  }

  return (
    <>
      <Modal show={show} onHide={props.onHide} size="sm" centered>
        <Modal.Header className="pl-4">
          <Modal.Title>{String.auth.changePassword}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-2">
          <Formik
            enableReinitialize
            initialValues={input}
            onSubmit={handleChangePassword}
            validate={(values) => {
              let errors = {}
              if (Boolean(values?.currentPassword.trim()) === false)
                errors.currentPassword = 'Please enter current password'
              else if (values.currentPassword.trim().length < 8)
                errors.currentPassword =
                  'Current Password must be 8 characters at minimum!'
              // else if (!passwordTest.test(values.currentPassword.trim()))
              //   errors.currentPassword =
              //     "Current password must be between 8 to 16 characters long and contain at least 1 number and 1 capital letter!";

              if (Boolean(values?.password.trim()) === false)
                errors.password = 'Please enter new password'
              else if (values.password.trim().length < 8)
                errors.password =
                  'New Password must be 8 characters at minimum!'
              // else if (!passwordTest.test(values.password.trim()))
              //   errors.password =
              //     "New password must be between 8 to 16 characters long and contain at least 1 number and 1 capital letter!";

              if (Boolean(values?.confirmPassword.trim()) === false)
                errors.confirmPassword = 'Please enter confirm password'
              else if (values.confirmPassword.trim().length < 8)
                errors.confirmPassword =
                  'Confirm Password must be 8 characters at minimum!'
              // else if (!passwordTest.test(values.confirmPassword.trim()))
              //   errors.confirmPassword =
              //     "Confirm Password must be between 8 to 16 characters long and contain at least 1 number and 1 capital letter!";
              if (
                values?.password.localeCompare(values?.confirmPassword) !== 0
              ) {
                errors.confirmPassword =
                  'Confirm Password must be same as password!'
              }
              return errors
            }}
          >
            {({ touched, errors }) => (
              <div className="mt-2">
                <Form>
                  <div>
                    <p className="text-54 f-15 mb-2 font-weight-bold ml-3">
                      {String.auth.currentPassword}
                    </p>
                  </div>
                  <div className="ml-3 mr-3">
                    <ThemeProvider theme={theme7}>
                      <TextField
                        label=""
                        error={
                          Object.keys(touched).length &&
                          Object.keys(errors).length
                        }
                        sx={{
                          '.Mui-error ': {
                            borderColor: ' #f64e60',
                          },

                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderWidth: '1px !important',
                            },
                          },
                          '& .Mui-error.MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#f64e60',
                            },
                            '&:hover fieldset': {
                              borderColor: '#f64e60',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#f64e60',
                            },
                          },
                        }}
                        variant="outlined"
                        inputProps={{
                          style: {
                            height,
                          },
                        }}
                        fullWidth
                        name="currentPassword"
                        value={input.currentPassword}
                        onChange={(e) => {
                          handleChange(e, 'currentPassword')
                        }}
                        type="password"
                      />
                    </ThemeProvider>
                  </div>
                  <div className="ml-3 mr-3">
                    <ErrorMessage
                      component="div"
                      name="currentPassword"
                      className="invalid-feedback"
                    />
                  </div>
                  <div>
                    <p className="text-54 f-15 mb-2 font-weight-bold ml-3">
                      {String.auth.newPassword}
                    </p>
                  </div>
                  <div className="ml-3 mr-3">
                    <ThemeProvider theme={theme7}>
                      <TextField
                        label=""
                        error={
                          Object.keys(touched).length &&
                          Object.keys(errors).length
                        }
                        sx={{
                          '.Mui-error ': {
                            borderColor: ' #f64e60',
                          },

                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderWidth: '1px !important',
                            },
                          },
                          '& .Mui-error.MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#f64e60',
                            },
                            '&:hover fieldset': {
                              borderColor: '#f64e60',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#f64e60',
                            },
                          },
                        }}
                        variant="outlined"
                        inputProps={{
                          style: {
                            height,
                          },
                        }}
                        fullWidth
                        name="password"
                        value={input.password}
                        onChange={(e) => {
                          handleChange(e, 'password')
                        }}
                        type="password"
                      />
                    </ThemeProvider>
                  </div>
                  <div className="ml-3 mr-3">
                    <ErrorMessage
                      component="div"
                      name="password"
                      className="invalid-feedback"
                    />
                  </div>
                  <div>
                    <p className="text-54 f-15 mb-2 font-weight-bold ml-3">
                      {String.auth.confirmNewPassword}
                    </p>
                  </div>
                  <div className="ml-3 mr-3">
                    <ThemeProvider theme={theme7}>
                      <TextField
                        label=""
                        error={
                          Object.keys(touched).length &&
                          Object.keys(errors).length
                        }
                        sx={{
                          '.Mui-error ': {
                            borderColor: ' #f64e60',
                          },

                          '& .MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderWidth: '1px !important',
                            },
                          },
                          '& .Mui-error.MuiOutlinedInput-root': {
                            '& fieldset': {
                              borderColor: '#f64e60',
                            },
                            '&:hover fieldset': {
                              borderColor: '#f64e60',
                            },
                            '&.Mui-focused fieldset': {
                              borderColor: '#f64e60',
                            },
                          },
                        }}
                        variant="outlined"
                        inputProps={{
                          style: {
                            height,
                          },
                        }}
                        fullWidth
                        name="confirmPassword"
                        value={input.confirmPassword}
                        onChange={(e) => {
                          handleChange(e, 'confirmPassword')
                        }}
                        type="password"
                      />
                    </ThemeProvider>
                  </div>
                  <div className="ml-3 mr-3 pb-2">
                    <ErrorMessage
                      component="div"
                      name="confirmPassword"
                      className="invalid-feedback"
                    />
                  </div>
                  <div className="mt-4 mb-3 d-flex justify-content-end border-top border-#ebedf3">
                    <Button
                      variant="secondary"
                      onClick={handleClose}
                      className="mt-3 mr-2"
                    >
                      {String.general.Close}
                    </Button>
                    <Button
                      className="mt-3 mr-3 btn btn-primary font-weight-bold"
                      type="submit"
                    >
                      <span className="f-17">
                        {' '}
                        {String.auth.updatePassword}
                      </span>
                      {loading && (
                        <span className="ml-3 spinner spinner-white mr-4"></span>
                      )}
                    </Button>
                  </div>
                </Form>
              </div>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})

export default connect(mapStateToProps, { logoutUser })(ChangePassword)
