import React, { Suspense } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import ViewCuisine from './ViewCuisine'
import {
  LayoutSplashScreen,
  ContentRoute,
} from '../../../_akhie_frontend/layout'

export default function cuisine() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from eCommerce root URL to /customers */
          <Redirect exact={true} from="/cuisine" to="/cuisine/view-cuisine" />
        }
        <ContentRoute path="/cuisine/view-cuisine" component={ViewCuisine} />
      </Switch>
    </Suspense>
  )
}
