import React, { Suspense, lazy } from 'react'
import { Redirect, Switch, Route } from 'react-router-dom'
import { LayoutSplashScreen, ContentRoute } from '../_akhie_frontend/layout'

import Dashboard from './modules/Dashboard/index'
import Cuisine from './modules/Cuisines/index'
import ContactUs from './modules/ContactUs/index'
import Events from './modules/Events/index'
import Revenue from './modules/Revenue/index'
import Users from './modules/Users/index'
import Transactions from './modules/Transactions/index'
import Reservation from './modules/Reservation/index'

const Settings = lazy(() => import('./modules/Settings/index'))

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }
        <ContentRoute path="/dashboard" component={Dashboard} />
        <Route path="/settings" component={Settings} />
        <Route path="/cuisine" component={Cuisine} />
        <Route path="/inquiry" component={ContactUs} />
        <Route path="/events" component={Events} />
        <Route path="/users" component={Users} />
        <Route path="/transactions" component={Transactions} />
        <Route path="/reservation" component={Reservation} />
        <Route path="/revenue" component={Revenue} />

        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  )
}
