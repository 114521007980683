import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik'
import { connect } from 'react-redux'
import { String } from '../../../../utils/string'
import { ReactComponent as Logo } from '../../../../_akhie_frontend/_assets/Logo.svg'
import APICallService from '../../../../api/apiCallService'
import apiJSON from '../../../../api/apiJSON'
import { LOGIN, PAGE_LIMIT } from '../../../../utils/constants'
import { registerUser } from '../../../actions/authActions'
import { toast } from 'react-toastify'
const initialValues = {
  email: 'admin@demo.com',
  password: 'demo',
}

const Login = (props) => {
  const { intl } = props
  const [loading, setLoading] = useState(false)

  const enableLoading = () => {
    setLoading(true)
  }

  const disableLoading = () => {
    setLoading(false)
  }

  const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
  return (
    <>
      <div className="col-lg-4 bg-white mt-10" id="kt_login_signin_form">
        <div className="mt-5 text-center">
          <Logo style={{ stroke: '#6959FC', height: '150px' }} />
        </div>
        <div className="mt-4 mb-3 d-flex justify-content-end border-top border-#ebedf3"></div>
        <div className="text-center mb-5 mb-lg-6">
          <h4 className="font-size-h4 font-weight-bold text-center text-54">
            {String.auth.signIn}
          </h4>
        </div>

        <Formik
          initialValues={{ email: '', password: '' }}
          validate={(values) => {
            let errors = {}

            if (Boolean(values?.email.trim()) === false)
              errors.email = 'Email is required!'
            else if (!emailTest.test(values.email))
              errors.Email = 'Invalid Email address format!'

            if (Boolean(values?.password.trim()) === false)
              errors.password = 'Password is required!'
            else if (values.password.length < 8)
              errors.password = 'Password must be 8 characters at minimum!'

            return errors
          }}
          onSubmit={async (values) => {
            enableLoading()
            let apiService = new APICallService(
              LOGIN,
              apiJSON.Login(values.email, values.password),
            )
            let response = await apiService.callAPI()

            if (response) {
              props.registerUser({ ...response })
              toast.success(String.messages.login)
            }else{
              
            }
            disableLoading()
          }}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form className="form fv-plugins-bootstrap fv-plugins-framework">
              <div className="form-group fv-plugins-icon-container">
                <Field
                  placeholder={String.placeHolder.email}
                  type="Email"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${
                    touched.email
                      ? errors.email
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  }`}
                  name="email"
                />
                <ErrorMessage
                  component="div"
                  name="email"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group fv-plugins-icon-container">
                <Field
                  placeholder={String.placeHolder.password}
                  type="password"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${
                    touched.password
                      ? errors.password
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  }`}
                  name="password"
                />
                <ErrorMessage
                  component="div"
                  name="password"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group text-center mb-0">
                <Link
                  to="/auth/forgot-password"
                  className="text-54 opacity9 font-weight-bold f-16 text-hover-primary my-3 mr-2"
                  id="kt_login_forgot"
                >
                  {String.auth.forgotPassword}
                </Link>
              </div>

              <div className="form-group text-center mb-0">
                <button
                  type="submit"
                  disabled={loading}
                  className={`btn btn-primary font-weight-bold px-9 py-3 my-3`}
                >
                  <span className="f-17">{String.auth.login}</span>
                  {loading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    </>
  )
}

// export default injectIntl(connect(null, auth.actions)(Login))

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})

export default connect(mapStateToProps, {
  registerUser,
})(Login)
