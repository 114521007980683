import setAuthToken from '../store/utility'
import * as actionTypes from '../store/actions/actionTypes'
import { toast } from 'react-toastify'

export const registerUser = (data) => (dispatch) => {
  localStorage.setItem('bearerToken', JSON.stringify(data.accessToken))
  delete data.accessToken
  localStorage.setItem('user', JSON.stringify(data))

  setAuthToken(data.accessToken)
  dispatch(setCurrentUser(data))
}
export const resetCode = (data) => (dispatch) => {
  localStorage.setItem('code', data)

  dispatch(setResetCode(data))
}

export const updateUser = (data) => (dispatch) => {
  localStorage.setItem('user', JSON.stringify(data))

  dispatch(setCurrentUser(data))
}

export const setCurrentUser = (user) => {
  return {
    type: actionTypes.SET_CURRENT_USER,
    payload: user,
  }
}
export const setResetCode = (code) => {
  return {
    type: actionTypes.SET_RESET_CODE,
    payload: code,
  }
}
export const setUserLoading = (loading) => {
  return {
    type: actionTypes.USER_LOADING,
    value: loading,
  }
}

export const logoutUser = () => (dispatch) => {
  localStorage.removeItem('bearerToken')
  localStorage.removeItem('user')

  setAuthToken(false)
  dispatch(setCurrentUser(null))
  window.location =
    window.location.protocol + '//' + window.location.host + '/auth/login'
}
