import React, { useEffect, useState } from 'react'
import CustomDatatable from '../Global/Datatable'
import { String } from '../../../utils/string'
import APICallService from '../../../api/apiCallService'
import apiJSON from '../../../api/apiJSON'
import { LISTRESERVATION, LISTUSERS, PAGE_LIMIT } from '../../../utils/constants'
import { Spinner } from 'react-bootstrap'
import { connect } from 'react-redux'
import { IconButton, InputBase, Paper } from '@mui/material'
import UserDetails from '../../modals/UserDetails'
import DeleteUser from '../../modals/DeleteUser'
import ActiveUser from '../../modals/ActiveUser'
import SearchIcon from '@mui/icons-material/Search'
import LightBox from '../Global/LightBox'
import Select from 'react-select'
import Method from '../../../utils/methods'
import moment from 'moment'
const ViewReservation = () => {
  const columns = [
    {
      name: String.general.index,
      selector: String.general.index,
      sortable: false,
    },
    {
      name: String.users.name,
      selector: String.cuisine.name,
      sortable: true,
    },
    {
      name: String.users.phoneNumber,
      selector: String.users.phoneNumber,
      sortable: false,
    }, {
      name: String.events.name,
      selector: String.events.name,
      sortable: false,
    },
    {
      name: String.general.action,
      selector: String.general.action,
      sortable: false,
    },
  ]
  const [show, setShow] = useState(false)
  const [userType, setUserType] = useState([1, 2, 3])
  const [selectedType, setSelectedType] = useState(0)
  const [active, setActive] = useState([true, false])
  const [isDeleted, setDeleted] = useState([true, false])
  const [images, setImages] = useState([])
  const [fetchLoader, setFetchLoader] = useState(false)
  const [inquiry, setInquiry] = useState([])
  const [totalPages, setTotalPages] = useState()
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [sortOrder, setSortOrder] = useState(-1)
  const [sort, setSort] = useState('createdAt')
  useEffect(() => {
    ; (async () => {
      setFetchLoader(true)
      setSort('createdAt')
      setSortOrder(-1)
      await fetchData(
        page,
        search,
        { createdAt: -1 },
        0,
        moment().unix()
      )
      setFetchLoader(false)
    })()
  }, [])
  const fetchData = async (
    pageNumber,
    searchTerm,
    sort,
    type, time
  ) => {
    setFetchLoader(true)
    let apiService = new APICallService(
      LISTRESERVATION,
      apiJSON.listReservation(
        PAGE_LIMIT,
        pageNumber,
        searchTerm,
        sort,
        type, time
      ),
    )
    let response = await apiService.callAPI()
    let data = []
    if (response) {
      response.data.map((dataVal, index) => {
        data.push({
          [String.general.index]: (pageNumber - 1) * PAGE_LIMIT + index + 1,
          [String.events.name]: dataVal.events.name,
          [String.cuisine.name]: dataVal.events.Host.name,
          [String.users.phoneNumber]:
            '+' + dataVal.events.Host.countryCode + ' ' + dataVal.events.Host.phoneNumber,
          [String.general.action]: (
            <>
              <ActiveUser
                _id={dataVal._id}
                isActive={dataVal.isActive}
                reload={() => reloading(1)}
              />
              <DeleteUser
                _id={dataVal._id}
                isDelete={dataVal.isDeleted}
                reload={() => reloading(1)}
              />
            </>
          ),
        })
      })
      setTotalPages(response.total)
    }
    setPage(pageNumber)
    setInquiry(data)
  }
  const reloading = async () => {
    setFetchLoader(true)
    setPage(1)
    fetchData(page, search, { [sort]: sortOrder }, userType, moment().unix())
    setUserType([1, 2, 3])
    setActive([true, false])
    setFetchLoader(false)
  }
  const handlePageChange = async (page) => {
    fetchData(page, search, { [sort]: sortOrder }, selectedType, moment().unix())
    setFetchLoader(false)
  }
  const handleSort = async (e) => {
    let order = sortOrder
    setSort(e.selector.toLowerCase())
    if (order === -1) {
      setSortOrder(1)
      await fetchData(
        1,
        search,
        { [e.selector.toLowerCase()]: 1 },
        userType,
        moment().unix()
      )
    } else {
      setSortOrder(-1)
      await fetchData(
        1,
        search,
        { [e.selector.toLowerCase()]: -1 },
        userType,
        moment().unix()
      )
    }
    setFetchLoader(false)
  }
  const handleType = async (e) => {
    if (e) {
      setSelectedType(e.value)
      await fetchData(
        1,
        search,
        { [sort]: sortOrder },
        e.value,
        moment().unix(),
      )
    } else {
    }
    setFetchLoader(false)
  }
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {show ? (
            <LightBox
              images={images}
              onHide={() => {
                setShow(false)
              }}
            />
          ) : (
            <></>
          )}
          <div className="card card-custom gutter-b example example-compact">
            <div className="card-header p-2 px-4 col-12 w-100 d-block">
              <div className="card-title row">
                <div className="col-lg-4">
                  <h3 className="card-label  mt-3 mb-2">
                    {String.users.title}
                  </h3>
                </div>
                <div className="col-lg-12 mt-3 d-flex justify-content-between">
                  <div className="col-lg-4">
                    <Select
                      className="w-100"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? '#6959FC' : '#ebedf3',
                        }),
                      }}
                      options={userType.map((value) => {
                        let label;
                        switch (value) {
                          case 0:
                            label = 'All';
                            break;
                          case 1:
                            label = 'UpComing';
                            break;
                          case 2:
                            label = 'Rejected';
                            break;
                          case 3:
                            label = 'Completed';
                            break;
                        }
                        return {
                          value: value,
                          label: label,
                          name: value,
                        };
                      })}
                      isClearable="true"
                      onChange={(e) => {
                        handleType(e)
                      }}
                      placeholder="Select User Type"
                    ></Select>
                  </div>
                </div>
              </div>
            </div>
            <div className="card-body">
              {/* {fetchLoader === false ? (
                <CustomDatatable
                  columns={columns}
                  data={inquiry}
                  className="dataTables_wrapper"
                  responsive={true}
                  highlightOnHover={false}
                  pagination={true}
                  striped={true}
                  paginationServer
                  paginationTotalRows={totalPages}
                  paginationPerPage={PAGE_LIMIT}
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                  onChangePage={(page) => {
                    handlePageChange(page)
                  }}
                  subHeader={false}
                  noHeader={true}
                  // progressPending={loading}
                  progressComponent={
                    <div className="d-flex justify-content-center mb-5">
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: '#115DC4' }}
                      >
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  }
                  subHeaderComponent={<></>}
                  onSort={handleSort}
                  sortServer={false}
                />
              ) : (
                <>
                  <div className="card-body">
                    <div className="d-flex justify-content-center">
                      <Spinner
                        animation="border"
                        variant="primary"
                        style={{ height: '4em', width: '4em' }}
                      />
                    </div>
                  </div>
                </>
              )} */}
              <CustomDatatable
                columns={columns}
                data={inquiry}
                className="dataTables_wrapper"
                responsive={true}
                highlightOnHover={false}
                pagination={true}
                striped={true}
                paginationServer
                paginationTotalRows={totalPages}
                paginationPerPage={PAGE_LIMIT}
                paginationComponentOptions={{
                  noRowsPerPage: true,
                }}
                onChangePage={(page) => {
                  handlePageChange(page)
                }}
                subHeader={false}
                noHeader={true}
                progressPending={fetchLoader}
                progressComponent={
                  <div className="d-flex justify-content-center mb-5">
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ color: '#115DC4' }}
                    >
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                }
                subHeaderComponent={<></>}
                onSort={handleSort}
                sortServer={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})
export default connect(mapStateToProps)(ViewReservation)
