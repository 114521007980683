import * as actionTypes from '../actions/actionTypes'

const initialState = {
  isAuthenticated: false,
  user: {},
  loading: false,
  code: 0,
}

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.SET_CURRENT_USER:
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload,
      }

    case actionTypes.USER_LOADING:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.SET_RESET_CODE:
      return {
        ...state,
        code: action.payload,
      }
    default:
      return state
  }
}
export default authReducer
