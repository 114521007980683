import React, { Suspense } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import ViewRevenue from './ViewRevenue'
import {
  LayoutSplashScreen,
  ContentRoute,
} from '../../../_akhie_frontend/layout'

export default function contactUs() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from eCommerce root URL to /customers */
          <Redirect exact={true} from="/revenue" to="/revenue/view-revenue" />
        }
        <ContentRoute path="/revenue/view-revenue" component={ViewRevenue} />
      </Switch>
    </Suspense>
  )
}
