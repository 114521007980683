import React, { useState } from 'react'
import clsx from 'clsx'
import { Button, Modal } from 'react-bootstrap'
import { String } from '../../utils/string'
import { DELETEUSER } from '../../utils/constants'
import APICallService from '../../api/apiCallService'
import apiJSON from '../../api/apiJSON'
import { toast } from 'react-toastify'
const DeleteUser = (props) => {
  const [show, setShow] = useState(false)

  const [loading, setLoading] = useState(false)
  const handleDelete = async () => {
    setLoading(true)
    let apiService = new APICallService(
      DELETEUSER,
      apiJSON.DeleteUser(props._id, !props.isDelete),
    )
    let response = await apiService.callAPI()

    toast.success(
      props.isDelete
        ? String.messages.userRestored
        : String.messages.userDeleted,
    )

    props.reload()
    setShow(false)
    setLoading(false)
  }
  return (
    <>
      {props.isDelete ? (
        <i
          className={clsx('fas fa-trash-restore icon-lg deleteIcon ml-2')}
          onClick={() => {
            setShow(!show)
          }}
        ></i>
      ) : (
        <i
          className={clsx(
            'flaticon2-rubbish-bin-delete-button icon-lg deleteIcon ml-2',
          )}
          onClick={() => {
            setShow(!show)
          }}
        ></i>
      )}

      <Modal
        show={show}
        onHide={() => {
          setShow(!show)
        }}
        size="md"
        centered
      >
        <Modal.Body>
          <div className="text-center my-3">
            <div className="mb-3">
              <p className="font-weight-bold delete-font">
                {props.isDelete
                  ? String.general.restore
                  : String.general.remove}{' '}
                {String.users.user}
              </p>
              <p className="text-delete">
                {props.isDelete
                  ? String.general.sureRestore
                  : String.general.sure}{' '}
                {String.users.user}?<br></br>{' '}
              </p>
            </div>
            <div className="mb-3">
              <Button
                variant="danger"
                onClick={handleDelete}
                disabled={loading}
                className="btn-delete"
              >
                <span className="f-17">Yes</span>
                {loading && (
                  <span className="ml-3 spinner spinner-white mr-3"></span>
                )}
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  setShow(!show)
                }}
                className="btn-delete"
              >
                No
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default DeleteUser
