import React, { useState } from 'react'
import clsx from 'clsx'
import { Button, Modal } from 'react-bootstrap'
import { String } from '../../utils/string'
import { ACTIVEUSER } from '../../utils/constants'
import APICallService from '../../api/apiCallService'
import apiJSON from '../../api/apiJSON'
import { toast } from 'react-toastify'
const ActiveUser = (props) => {
  const [show, setShow] = useState(false)

  const [loading, setLoading] = useState(false)
  const handleActive = async () => {
    setLoading(true)
    let apiService = new APICallService(
      ACTIVEUSER,
      apiJSON.ActiveUser(props._id, !props.isActive),
    )
    let response = await apiService.callAPI()

    toast.success(
      props.isActive
        ? String.messages.userDeactive
        : String.messages.userActive,
    )

    props.reload()
    setShow(false)
    setLoading(false)
  }
  return (
    <>
      {props.isActive ? (
        <i
          className={clsx('fas fa-lock-open icon-lg lockIcon ml-2')}
          onClick={() => {
            setShow(!show)
          }}
        ></i>
      ) : (
        <i
          className={clsx('fas fa-lock icon-lg lockIcon ml-2')}
          onClick={() => {
            setShow(!show)
          }}
        ></i>
      )}
      <Modal
        show={show}
        onHide={() => {
          setShow(!show)
        }}
        size="md"
        centered
      >
        <Modal.Body>
          <div className="text-center my-3">
            <div className="mb-3">
              <p className="font-weight-bold delete-font">
                {props.isActive
                  ? String.general.deactivate
                  : String.general.activate}{' '}
                {String.users.user}
              </p>
              <p className="text-delete">
                {props.isActive
                  ? String.general.sureDeactivate
                  : String.general.sureActivate}{' '}
                {String.users.user}?<br></br>
              </p>
            </div>
            <div className="mb-3">
              <Button
                variant="danger"
                onClick={handleActive}
                disabled={loading}
                className="btn-delete"
              >
                <span className="f-17">Yes</span>
                {loading && (
                  <span className="ml-3 spinner spinner-white mr-3"></span>
                )}
              </Button>
              <Button
                variant="success"
                onClick={() => {
                  setShow(!show)
                }}
                className="btn-delete"
              >
                No
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default ActiveUser
