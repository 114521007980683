import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Formik, Form, Field, ErrorMessage, useFormik } from 'formik'
import { connect } from 'react-redux'
import { String } from '../../../utils/string'
import { ReactComponent as Logo } from '../../../_akhie_frontend/_assets/Logo.svg'
import APICallService from '../../../api/apiCallService'
import apiJSON from '../../../api/apiJSON'
import { CONTACTUS, LOGIN, PAGE_LIMIT } from '../../../utils/constants'
import { toast } from 'react-toastify'

const AddInquiry = (props) => {
  const { intl } = props
  const [loading, setLoading] = useState(false)

  const enableLoading = () => {
    setLoading(true)
  }

  const disableLoading = () => {
    setLoading(false)
  }

  const emailTest = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Content*/}
          <div
            className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden"
            style={{ background: 'whitesmoke' }}
          >
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
            <div className="col-lg-4 bg-white mt-10" id="kt_login_signin_form">
        <div className="mt-5 text-center">
          <Logo style={{ stroke: '#6959FC', height: '150px' }} />
        </div>
        <div className="mt-4 mb-3 d-flex justify-content-end border-top border-#ebedf3"></div>
        <div className="text-center mb-5 mb-lg-6">
          <h4 className="font-size-h4 font-weight-bold text-center text-54">
            {String.auth.contactUs}
          </h4>
        </div>

        <Formik
          initialValues={{ email: '', message: '' }}
          validate={(values) => {
            let errors = {}

            if (Boolean(values?.email.trim()) === false)
              errors.email = 'Email is required!'
            else if (!emailTest.test(values.email))
              errors.Email = 'Invalid Email address format!'

            if (Boolean(values?.message.trim()) === false)
              errors.message = 'Message is required!'
           
            return errors
          }}
          onSubmit={async (values) => {
            enableLoading()
            let apiService = new APICallService(
              CONTACTUS,
              apiJSON.ContactUs(values.email, values.message),
            )
            let response = await apiService.callAPI()

           
              toast.success("Inquiry Sent")
            
            disableLoading()
          }}
        >
          {({ touched, errors, isSubmitting }) => (
            <Form className="form fv-plugins-bootstrap fv-plugins-framework">
              <div className="form-group fv-plugins-icon-container">
                <Field
                  placeholder={String.placeHolder.email}
                  type="Email"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${
                    touched.email
                      ? errors.email
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  }`}
                  name="email"
                />
                <ErrorMessage
                  component="div"
                  name="email"
                  className="invalid-feedback"
                />
              </div>
              <div className="form-group fv-plugins-icon-container">
                <Field
                  placeholder={String.placeHolder.message}
as="textarea"
                  className={`form-control form-control-solid h-auto py-5 px-6 ${
                    touched.message
                      ? errors.message
                        ? 'is-invalid'
                        : 'is-valid'
                      : ''
                  }`}
                  name="message"
                />
                <ErrorMessage
                  component="div"
                  name="message"
                  className="invalid-feedback"
                />
              </div>
              

              <div className="form-group text-center mb-0">
                <button
                  type="submit"
                  disabled={loading}
                  className={`btn btn-primary font-weight-bold px-9 py-3 my-3`}
                >
                  <span className="f-17">{"Submit"}</span>
                  {loading && (
                    <span className="ml-3 spinner spinner-white"></span>
                  )}
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}

            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
     
    </>
  )
}



export default AddInquiry
