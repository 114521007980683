import React, { useState } from 'react'
import { Carousel } from 'react-bootstrap'
import { connect } from 'react-redux'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import ListItemText from '@mui/material/ListItemText'
import ListItem from '@mui/material/ListItem'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import AppBar from '@mui/material/AppBar'
import Toolbar from '@mui/material/Toolbar'
import IconButton from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import { Modal } from 'react-bootstrap'
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ControlledCarousel = (props) => {
  const [index, setIndex] = useState(0)
  const [open, setOpen] = React.useState(props.open)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    props.close()
  }
  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex)
  }

  return (
    <Dialog
      fullScreen
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
    >
      <Carousel activeIndex={index} onSelect={handleSelect} variant="dark">
        {props.images.images.map((val) => {
          return (
            <Carousel.Item className="h-100vh">
              <img
                className="img-fluid sliderImage"
                // width={'100px'}
                src={val.originalImage}
                alt="First slide"
              />
              {/* <Carousel.Caption>
                <h3 className="text-black">{props.images.name}</h3>
                <p className="text-black">{props.images.description}</p>
              </Carousel.Caption> */}
            </Carousel.Item>
          )
        })}
      </Carousel>
    </Dialog>
    // <Modal show={true} fullscreen={true}>
    //   <Modal.Header closeButton>
    //     <Modal.Title>Modal title</Modal.Title>
    //   </Modal.Header>

    //   <Modal.Body>
    //     <Carousel activeIndex={index} onSelect={handleSelect}>
    //       <Carousel.Item>
    //         <img
    //           className="d-block w-100"
    //           src="holder.js/800x400?text=First slide&bg=373940"
    //           alt="First slide"
    //         />
    //         <Carousel.Caption>
    //           <h3>First slide label</h3>
    //           <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
    //         </Carousel.Caption>
    //       </Carousel.Item>
    //       <Carousel.Item>
    //         <img
    //           className="d-block w-100"
    //           src="holder.js/800x400?text=Second slide&bg=282c34"
    //           alt="Second slide"
    //         />

    //         <Carousel.Caption>
    //           <h3>Second slide label</h3>
    //           <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
    //         </Carousel.Caption>
    //       </Carousel.Item>
    //       <Carousel.Item>
    //         <img
    //           className="d-block w-100"
    //           src="holder.js/800x400?text=Third slide&bg=20232a"
    //           alt="Third slide"
    //         />

    //         <Carousel.Caption>
    //           <h3>Third slide label</h3>
    //           <p>
    //             Praesent commodo cursus magna, vel scelerisque nisl consectetur.
    //           </p>
    //         </Carousel.Caption>
    //       </Carousel.Item>
    //     </Carousel>
    //   </Modal.Body>
    // </Modal>
    // </div>
  )
}

// render(<ControlledCarousel />)
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})

export default connect(mapStateToProps)(ControlledCarousel)
