import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import AddInquiry from "./AddInquiry";


export default function Index() {
  return (
    <Switch>
      <Redirect from="/contact-us" exact={true} to="/contact-us/index" />
      <Route path="/contact-us/index" component={AddInquiry} />
    
    </Switch>
  );
}
