export const String = {
  auth: {
    signIn: 'Admin Sign In',
    contactUs: 'Contact Us',
    forgotPassword: 'Forgot Password?',
    login: 'Login',
    forgotPasswordText: 'Enter your email to reset your password',
    backToLogin: 'Go back to login',
    resetPassword: 'Reset Password',
    verified: 'Choose an new password',
    newPassword: 'New Password',
    confirmNewPassword: 'Confirm New Password',
    changePassword: 'Change Password',
    currentPassword: 'Current Password',
    updatePassword: 'Update Password',
  },
  placeHolder: {
    email: 'Email',
    password: 'Password',
    message: 'Message',
  },
  messages: {
    login: 'Login Successful!!',
    forgotPassword:
      'Reset password link sent on your email, please check email.',
    appSettings: 'App settings updated successfully!!',
    aboutUs: 'About us updated successfully!!',
    privacyPolicy: 'Privacy policy updated successfully!!',
    cuisineAdded: 'Cuisine added successfully!!',
    cuisineEdited: 'Cuisine edited successfully!!',
    cuisineDeleted: 'Cuisine deleted successfully!!',
    userDeleted: 'User Deleted successfully!!',
    userRestored: 'User Restored successfully!!',
    userActive: 'User Activated successfully!!',
    userDeactive: 'User Deactivated successfully!!',
    passwordUpdate: 'Your password has been changed successfully!!',
    passwordReset: 'Your password has been reseted successfully!!',
  },
  general: {
    submit: 'Submit',
    update: 'Update',
    add: 'Add',
    action: 'Action',
    index: '#',
    _id: '_id',
    sure: 'Are you sure you want to remove this',
    sureRestore: 'Are you sure you want to restore this',
    sureActivate: 'Are you sure you want to activate this',
    sureDeactivate: 'Are you sure you want to deactive this',
    remove: 'Remove',
    restore: 'Restore',
    activate: 'Activate',
    deactivate: 'Deactivate',
    recover: 'You will not be able to recover this later.',
    Close: 'Close',
  },
  settings: {
    androidVersion: 'Android Version',
    iosVersion: 'iOS Version',
    androidForceUpdate: 'Android Force Update',
    iosForceUpdate: 'iOS Force Update',
    maintenance: 'Maintenance',
    title: 'App Settings',
  },
  aboutUs: {
    title: 'About Us',
  },
  policy: {
    title: 'Privacy Policy',
  },
  cuisine: {
    addTitle: 'Add Cuisine',
    cuisineName: 'Cuisine Name',
    viewTitle: 'View Cuisine',
    editTitle: 'Edit Cuisine',
    name: 'name',
    cuisine: 'Cuisine',
  },
  dashboard: {
    users: 'Users',
    inquiry: 'Inquiry',
    events: 'Events',
    host: 'Host',
    guest: 'Guest',
    cuisine: 'Cuisine',
  },
  inquiry: {
    title: 'View Inquiries',
    message: 'Message',
    email: 'Email',
  },
  users: {
    title: 'View Users',
    name: 'Full name',
    email: 'Email',
    phoneNumber: 'Phone Number',
    profile: 'Profile Image',
    type: 'User Type',
    totalReview: 'Total Review',
    averageReview: 'Average Review',
    reservations: 'Total Event Joined',
    titleDetail: 'User Details',
    reviewGiven: 'Total Review Given',
    user: 'User',
    amount: 'Amount',
    transactionId: 'Transaction',
    transactionDate: 'Date',
    transactionTitle: 'View Transactions',
  },
  events: {
    title: 'View Events',
    name: 'name',
    eventName: 'Event Name',
    description: 'Description',
    thumbnail: 'Thumbnail',
    image: 'image',
    details: 'Event Details',
    type: 'Event Type',
    allergies: 'Allergies',
    rules: 'Rules',
    maxGuest: 'Max Guest',
    maxTakeAway: 'Max TakeAway',
    item: 'Items',
    time: 'time',
    eventDate: 'Event Date',
    eventStartTime: 'Event Start Time',
    eventEndTime: 'Event End Time',
    organizer: 'Host Details',
    reviews: 'Host Review',
    eventTime: 'Event Time',
    reservations: 'Total Reservations',
    startDate: 'startDate',
    location: 'Event Location',
    price: 'Price',
    takeaway: 'TakeAways',
    foodText: 'The Food Guest will Get',
    rules: 'Rules and Regulations',
    hostDetails: 'Host Details',
    person: 'person',
    hostName: 'Host Name',
    averageReview: 'Average Review',
    totalReview: 'Total Review',
    dineIn: 'Dine-In',
  },
  revenue: {
    name: 'name',
    eventName: 'Event Name',
    description: 'Description',
    image: 'Image',
    thumbnail: 'Thumbnail',
    eventDate: 'EventDate',
    reservations: 'Reservations',
    price: 'price',
    totalAmount: 'Total Amount',
    revenueEarned: 'Revenue Earned',
    totalEarned: 'Total Revenue Earned',
    title: 'View Revenue',
  },
}
export const Error = {
  NET_ERR: 'Please check your internet connection and try again!',
}
