import React, { useEffect, useState } from 'react'
import CustomDatatable from '../Global/Datatable'
import { String } from '../../../utils/string'
import APICallService from '../../../api/apiCallService'
import apiJSON from '../../../api/apiJSON'
import { USERTRANSACTION, PAGE_LIMIT } from '../../../utils/constants'
import { Spinner } from 'react-bootstrap'
import { connect } from 'react-redux'
import { IconButton, InputBase, Paper } from '@mui/material'
import UserDetails from '../../modals/UserDetails'
import DeleteUser from '../../modals/DeleteUser'
import ActiveUser from '../../modals/ActiveUser'
import SearchIcon from '@mui/icons-material/Search'
import LightBox from '../Global/LightBox'
import Select from 'react-select'
import moment from 'moment'
const ViewTransactions = () => {
  const columns = [
    {
      name: String.general.index,
      selector: String.general.index,
      sortable: false,
    },
    {
      name: String.users.transactionDate,
      selector: "created",
      sortable: true,
    },
    {
      name: String.users.name,
      selector: String.cuisine.name,
      sortable: true,
    },
    {
      name: String.users.phoneNumber,
      selector: "number",
      sortable: true,
    },
    {
      name: String.users.amount,
      selector: "amount",
      sortable: true,
    },
    {
      name: String.users.transactionId,
      selector: "destination_payment",
      sortable: true,
    },
    // {
    //   name: String.general.action,
    //   selector: String.general.action,
    //   sortable: false,
    // },
  ]
  const [show, setShow] = useState(false)
  const [images, setImages] = useState([])
  const [fetchLoader, setFetchLoader] = useState(false)
  const [inquiry, setInquiry] = useState([])
  const [totalPages, setTotalPages] = useState()
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [sortOrder, setSortOrder] = useState(-1)
  const [sort, setSort] = useState('created')
  useEffect(() => {
    ; (async () => {
      setFetchLoader(true)
      setSort('createdAt')
      setSortOrder(-1)
      await fetchData(page, search, { createdAt: -1 })
      setFetchLoader(false)
    })()
  }, [])
  const fetchData = async (pageNumber, searchTerm, sort) => {
    setFetchLoader(true)
    if (Object.keys(sort).includes("number")) {
      sort = { ["users.countryCode"]: sortOrder, ["users.phoneNumber"]: sortOrder }
    }
    let apiService = new APICallService(
      USERTRANSACTION,
      apiJSON.listGeneral(PAGE_LIMIT, pageNumber, searchTerm, sort),
    )
    let response = await apiService.callAPI()
    let data = []
    if (response) {
      response[0].data.map((dataVal, index) => {
        data.push({
          [String.general.index]: (pageNumber - 1) * PAGE_LIMIT + index + 1,
          ["created"]: moment
            .unix(dataVal.created)
            .utc()
            .format('DD/MM/YYYY'),
          ["number"]:
            '+' + dataVal.users.countryCode + ' ' + dataVal.users.phoneNumber,
          [String.cuisine.name]: dataVal.users.name,
          ["amount"]: dataVal.amount.toFixed(2),
          ["destination_payment"]: dataVal.destination_payment,
        })
      })
      setTotalPages(response[0].total)
    }
    setPage(pageNumber)
    setInquiry(data)
  }
  const handlePageChange = async (page) => {
    fetchData(page, search, { [sort]: sortOrder })
    setFetchLoader(false)
  }
  const handleSearch = async (e) => {
    if (e.target.value.length) {
      setSearch(e.target.value)
      if (e.target.value.length >= 2) {
        await fetchData(1, e.target.value, { [sort]: sortOrder })
        setPage(1)
      }
    } else {
      setSearch('')
      setPage(1)
      await fetchData(1, '', { [sort]: sortOrder })
    }
    setFetchLoader(false)
  }
  const handleSort = async (e) => {
    let order = sortOrder
    if (order === -1) {
      if (e.selector == "name") {
        e.selector = "users.name"
      }
      setSort(e.selector)
      setSortOrder(1)
      await fetchData(1, search, { [e.selector]: 1 })
    } else {
      if (e.selector == "name") {
        e.selector = "users.name"
      }
      setSort(e.selector)
      setSortOrder(-1)
      await fetchData(1, search, { [e.selector]: -1 })
    }
    setFetchLoader(false)
  }
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {show ? (
            <LightBox
              images={images}
              onHide={() => {
                setShow(false)
              }}
            />
          ) : (
            <></>
          )}
          <div className="card card-custom gutter-b example example-compact">
            <div className="card-header p-2 px-4 col-12 w-100 d-block">
              <div className="card-title row">
                <div className="col-lg-4">
                  <h3 className="card-label  mt-3 mb-2">
                    {String.users.transactionTitle}
                  </h3>
                </div>
                <div className="col-lg-8 d-flex justify-content-end">
                  <Paper className="searchText">
                    <InputBase
                      className={' px-3'}
                      placeholder="Search"
                      inputProps={{ 'aria-label': 'Search Google Maps' }}
                      onChange={(e) => {
                        handleSearch(e, 1)
                      }}
                      name="name"
                    />
                    <IconButton
                      className=""
                      aria-label="Search"
                      onClick={() => {
                        handleSearch(search, 1)
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>
              </div>
              {/* <div className="separator separator-dashed my-7"></div> */}
            </div>
            <div className="card-body">
              {/* {fetchLoader === false ? (
                <CustomDatatable
                  columns={columns}
                  data={inquiry}
                  className="dataTables_wrapper"
                  responsive={true}
                  highlightOnHover={false}
                  pagination={true}
                  striped={true}
                  paginationServer
                  paginationTotalRows={totalPages}
                  paginationPerPage={PAGE_LIMIT}
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                  onChangePage={(page) => {
                    handlePageChange(page)
                  }}
                  subHeader={false}
                  noHeader={true}
                  // progressPending={loading}
                  progressComponent={
                    <div className="d-flex justify-content-center mb-5">
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: '#115DC4' }}
                      >
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  }
                  subHeaderComponent={<></>}
                  onSort={handleSort}
                  sortServer={false}
                />
              ) : (
                <>
                  <div className="card-body">
                    <div className="d-flex justify-content-center">
                      <Spinner
                        animation="border"
                        variant="primary"
                        style={{ height: '4em', width: '4em' }}
                      />
                    </div>
                  </div>
                </>
              )} */}
              <CustomDatatable
                columns={columns}
                data={inquiry}
                className="dataTables_wrapper"
                responsive={true}
                highlightOnHover={false}
                pagination={true}
                striped={true}
                paginationServer
                paginationTotalRows={totalPages}
                paginationPerPage={PAGE_LIMIT}
                paginationComponentOptions={{
                  noRowsPerPage: true,
                }}
                onChangePage={(page) => {
                  handlePageChange(page)
                }}
                subHeader={false}
                noHeader={true}
                progressPending={fetchLoader}
                progressComponent={
                  <div className="d-flex justify-content-center mb-5">
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ color: '#115DC4' }}
                    >
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                }
                subHeaderComponent={<></>}
                onSort={handleSort}
                sortServer={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})
export default connect(mapStateToProps)(ViewTransactions)
