import Lightbox from 'react-awesome-lightbox'
// You need to import the CSS only once
import 'react-awesome-lightbox/build/style.css'
import React, { Fragment, useState } from 'react'
import { connect } from 'react-redux'

const LightBox = (props) => {
  const [images, setImages] = useState(props.images)
  return (
    <Fragment>
      <Lightbox image={images} onClose={props.onHide}></Lightbox>
    </Fragment>
  )
}

const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})

export default connect(mapStateToProps)(LightBox)
