import { useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap'
import SVG from 'react-inlinesvg'
import { Link, useHistory } from 'react-router-dom'
import { String } from '../../../utils/string'
import { toAbsoluteUrl } from '../../../_akhie_frontend/_helpers'
import { DASHBOARD } from '../../../utils/constants'
import APICallService from '../../../api/apiCallService'
const Dashboard = () => {
  const [loading, setLoading] = useState(false)
  const [dashboardData, setDashboard] = useState()
  let history = useHistory()
  useEffect(() => {
    ; (async () => {
      setLoading(true)
      const token = localStorage.bearerToken
      const user = localStorage.user
      if (Boolean(token) === false || Boolean(user) === false) {
        localStorage.removeItem('bearerToken')
        localStorage.removeItem('user')
        window.location =
          window.location.protocol + '//' + window.location.host + '/auth/login'
      }
      await fetchData()
      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const fetchData = async (pageNumber, searchTerm, sort) => {
    setLoading(true)
    let apiService = new APICallService(
      DASHBOARD,
    )
    let response = await apiService.callAPI()
    let data = []
    if (response) {
      setDashboard(response)
    }
    setLoading(data)
  }
  return (
    <>
      {loading === false ? (
        <>
          <div className="card-spacer ">
            <div className="row m-0">
              <div className="col-lg-12">
                <div className="col-lg-3 float-left">
                  <div className="col-lg-12 bg-light-info px-6 py-8 rounded-xl mb-7">
                    <div className="row">
                      <div className="col-lg-12 float-left d-flex">
                        <div className="col-lg-6 col-md-6 col-sm-12 float-left d-flex justify-content-start">
                          <span className="svg-icon svg-icon-3x svg-icon-info d-block my-2">
                            <SVG
                              src={toAbsoluteUrl(
                                '/media/svg/icons/Communication/Add-user.svg',
                              )}
                            ></SVG>
                          </span>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-12 float-left d-flex justify-content-end">
                          <span className="d-block my-2 symbol symbol-light-info symbol-45">
                            <span className="symbol-label font-weight-bolder font-size-h2">
                              {dashboardData ? dashboardData.user : 0}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 d-flex">
                        <div className="col-lg-12 col-md-6 col-sm-12 float-left d-flex justify-content-start">
                          <Link
                            to="users/view-users/0"
                            className="text-info font-weight-bold font-size-h6 mt-2"
                          >
                            {String.dashboard.users}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 float-left">
                  <div className="col-lg-12 bg-light-warning px-6 py-8 rounded-xl mb-7">
                    <div className="row">
                      <div className="col-lg-12 float-left d-flex">
                        <div className="col-lg-6 col-md-6 col-sm-12 float-left d-flex justify-content-start">
                          <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                            <SVG
                              src={toAbsoluteUrl(
                                '/media/svg/icons/General/User.svg',
                              )}
                            ></SVG>
                          </span>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-12 float-left d-flex justify-content-end">
                          <span className="d-block my-2 symbol symbol-light-warning symbol-45">
                            <span className="symbol-label font-weight-bolder font-size-h2">
                              {dashboardData ? dashboardData.host : 0}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 d-flex">
                        <div className="col-lg-12 col-md-6 col-sm-12 float-left d-flex justify-content-start">
                          <Link
                            key={1}
                            to="users/view-users/1"
                            className="text-warning font-weight-bold font-size-h6 mt-2"
                          >
                            {String.dashboard.host}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 float-left">
                  <div className="col-lg-12 bg-light-success px-6 py-8 rounded-xl mb-7">
                    <div className="row">
                      <div className="col-lg-12 float-left d-flex">
                        <div className="col-lg-6 col-md-6 col-sm-12 float-left d-flex justify-content-start">
                          <span className="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                            <SVG
                              src={toAbsoluteUrl(
                                '/media/svg/icons/Communication/Group.svg',
                              )}
                            ></SVG>
                          </span>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-12 float-left d-flex justify-content-end">
                          <span className="d-block my-2 symbol symbol-light-success symbol-45">
                            <span className="symbol-label font-weight-bolder font-size-h2">
                              {dashboardData ? dashboardData.guest : 0}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 d-flex">
                        <div className="col-lg-12 col-md-6 col-sm-12 float-left d-flex justify-content-start">
                          <Link
                            to="users/view-users/2"
                            key={2}
                            className="text-success font-weight-bold font-size-h6 mt-2"
                          >
                            {String.dashboard.guest}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 float-left">
                  <div className="col-lg-12 bg-light-danger px-6 py-8 rounded-xl mr-7 mb-7">
                    <div className="row">
                      <div className="col-lg-12 float-left d-flex">
                        <div className="col-lg-6 col-md-6 col-sm-12 float-left d-flex justify-content-start">
                          <span className="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                            <SVG
                              src={toAbsoluteUrl(
                                '/media/svg/icons/Communication/Call.svg',
                              )}
                            ></SVG>
                          </span>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-12 float-left d-flex justify-content-end">
                          <span className="d-block my-2 symbol symbol-light-danger symbol-45">
                            <span className="symbol-label font-weight-bolder font-size-h2">
                              {dashboardData ? dashboardData.inquiry : 0}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 d-flex">
                        <div className="col-lg-12 col-md-6 col-sm-12 float-left d-flex justify-content-start">
                          <Link
                            to="inquiry/view-inquiry"
                            className="text-danger font-weight-bold font-size-h6 mt-2"
                          >
                            {String.dashboard.inquiry}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row m-0">
              <div className="col-lg-12">
                <div className="col-lg-3 float-left">
                  <div className="col-lg-12 bg-light-info px-6 py-8 rounded-xl mb-7">
                    <div className="row">
                      <div className="col-lg-12 float-left d-flex">
                        <div className="col-lg-6 col-md-6 col-sm-12 float-left d-flex justify-content-start">
                          <span className="svg-icon svg-icon-3x svg-icon-info d-block my-2">
                            <SVG
                              src={toAbsoluteUrl(
                                '/media/svg/icons/Cooking/KnifeAndFork1.svg',
                              )}
                            ></SVG>
                          </span>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-12 float-left d-flex justify-content-end">
                          <span className="d-block my-2 symbol symbol-light-info symbol-45">
                            <span className="symbol-label font-weight-bolder font-size-h2">
                              {dashboardData ? dashboardData.cuisine : 0}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 d-flex">
                        <div className="col-lg-12 col-md-6 col-sm-12 float-left d-flex justify-content-start">
                          <Link
                            to="cuisine/view-cuisine"
                            className="text-info font-weight-bold font-size-h6 mt-2"
                          >
                            {String.dashboard.cuisine}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 float-left">
                  <div className="col-lg-12 bg-light-warning px-6 py-8 rounded-xl mb-7">
                    <div className="row">
                      <div className="col-lg-12 float-left d-flex">
                        <div className="col-lg-6 col-md-6 col-sm-12 float-left d-flex justify-content-start">
                          <span className="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                            <SVG
                              src={toAbsoluteUrl(
                                '/media/svg/icons/Cooking/KnifeAndFork2.svg',
                              )}
                            ></SVG>
                          </span>
                        </div>
                        <div className="col-lg-7 col-md-6 col-sm-12 float-left d-flex justify-content-end">
                          <span className="d-block my-2 symbol symbol-light-warning symbol-45">
                            <span className="symbol-label font-weight-bolder font-size-h2">
                              {dashboardData ? dashboardData.event : 0}
                            </span>
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-lg-12 d-flex">
                        <div className="col-lg-12 col-md-6 col-sm-12 float-left d-flex justify-content-start">
                          <Link
                            to="events/view-events"
                            className="text-warning font-weight-bold font-size-h6 mt-2"
                          >
                            {String.dashboard.events}
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="d-flex justify-content-center align-items-center">
          <Spinner
            animation="border"
            variant="primary"
            style={{ height: '4em', width: '4em' }}
          />
        </div>
      )}
    </>
  )
}
export default Dashboard
