import moment from 'moment'
import secureLocalStorage from 'react-secure-storage'
import APICallService from '../api/apiCallService'
import { Error } from './string'
import GlobalValidations from './validations'
// import { FETCH } from '../redux/types'
import { toast } from 'react-toastify'

const Method = {
  savePref: (key, value) => {
    secureLocalStorage.setItem(key, JSON.stringify(value))
  },
  convertDate: (date) => {
    return moment(date, 'DD-MM-YYYY').format('MMM DD, YYYY')
  },
  convertDateToDDMMYYYY: (date) => {
    return moment(date, 'DD-MM-YYYY').format('DD/MM/YYYY')
  },
  convertDateToDateAndTime: (date) => {
    return moment(date).format('DD/MM/YYYY - HH:mm')
  },
  convertStartTimeToUtc: (date) => {
    var time = moment(date + ' ' + time, 'YYYY-MM-DD HH:mm:ss')
    var converteddate = new Date(time).toUTCString()
    return converteddate
  },
  convertCurrentTimeToUtc: () => {
    var date = moment().format('YYYY-MM-DD HH:mm:ss')
    var time = moment(date, 'YYYY-MM-DD HH:mm:ss')
    var startTime = new Date(time).toUTCString()
    return startTime
  },
  
  convertStartDate: (date) => {
    return moment(date).format('YYYY-MM-DD')
  },
  converStartTime: (date) => {
    return moment(date).format('HH:mm:ss')
  },
  converDurationTime: (time) => {
    return moment(time, 'HH:mm:ss').format('HH:mm')
  },
  saveStringPref: (key, value) => {
    localStorage.setItem(key, value, (err) => {})
  },
  getFromNowTime: (date) => {
    var data = moment(date).utc().local().format('YYYY/MM/DD HH:mm:ss')
    var difference = moment().diff(data, 'DD/MM/YYYY')
    var momDiff = moment.duration(difference)
    var diffToDays = Math.round(momDiff.asDays())
    if (diffToDays <= 3) {
      return moment(data).fromNow(true)
    } else {
      return moment(data).format('DD/MM/YYYY')
    }
  },
  getPref: (key) => {
    return new Promise((resolve, reject) => {
      let result = localStorage.getItem(key)
      if (result) {
        resolve(JSON.parse(result))
      } else {
        reject(false)
      }
    })
  },
  getStringPref: (key) => {
    return new Promise((resolve, reject) => {
      localStorage.getItem(key, (err, result) => {
        if (result) {
          resolve(result)
        } else {
          reject(false)
        }
      })
    })
  },

  showNetworkAlert: () => {
    toast.error(Error.NET_ERR)
  },

  showAPIErrorAlertWithExit: (error) => {
    toast.error(error)
  },
  checkNetwork() {
    return new Promise((resolve, reject) => {
      GlobalValidations.checkNetConnection().then(
        (result) => {
          if (result) {
            resolve(result)
          } else {
            this.showNetworkAlert()
          }
        },
        (error) => {
          reject(error)
        },
      )
    })
  },

  isSuccess(res) {
    if (res.Status === 200) {
      return true
    } else {
      return false
    }
  },

  apiCall(apiname, body, loader) {
    return () => {
      console.log('Api name', apiname, body)
      // if (loader == undefined) {
      //   dispatch(updateLoader(true))
      // } else {
      //   dispatch(updateLoader(false))
      // }

      // dispatch({ type: apiname + FETCH, payload: '' })
      const apiClass = new APICallService(apiname, body)
      apiClass
        .callAPI()
        .then(async (res) => {
          console.log('res.data => ', res.data)
          // await HandleResponse(dispatch, apiname, res)
        })
        .catch(async (err) => {
          // await fetchFail(dispatch, err, apiname)
          // await dispatch(updateLoader(false))
        })
    }
  },
  removePref: async () => {
    secureLocalStorage.clear()
  },
}

export default Method
