import React, { useEffect, useState } from 'react'
import CustomDatatable from '../Global/Datatable'
import { String } from '../../../utils/string'
import APICallService from '../../../api/apiCallService'
import apiJSON from '../../../api/apiJSON'
import { EVENTLOCATION, LISTEVENTS, PAGE_LIMIT } from '../../../utils/constants'
import { Spinner } from 'react-bootstrap'
import { connect } from 'react-redux'
import { IconButton, InputBase, Paper } from '@mui/material'
import Select from 'react-select'
import SearchIcon from '@mui/icons-material/Search'
import { Link } from 'react-router-dom'
import DateRangePicker from '../../customComponent/DateRange/dist/index'
import moment from 'moment'
import '../../customComponent/DateRange/dateRange.css'
const ViewEvents = () => {
  const columns = [
    {
      name: String.general.index,
      selector: String.general.index,
      sortable: false,
    },
    {
      name: String.events.eventName,
      selector: String.events.name,
      sortable: true,
    },
    {
      name: String.events.eventDate,
      selector: "eventDate",
      sortable: true,
    },
    {
      name: String.events.eventTime,
      selector: "eventStartTime",
      sortable: true,
    },
    {
      name: String.general.action,
      selector: String.general.action,
      sortable: false,
    },
  ]
  const [fetchLoader, setFetchLoader] = useState(false)
  const [inquiry, setInquiry] = useState([])
  const [totalPages, setTotalPages] = useState()
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [sortOrder, setSortOrder] = useState(-1)
  const [sort, setSort] = useState('')
  const [location, setLocation] = useState()
  const [currentLocation, setCurrentLocation] = useState('')
  const [dates, setDatesState] = useState({
    startDate: '',
    endDate: '',
  })
  const ranges = {
    Today: [moment(), moment()],
    Yesterday: [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
    'Last 7 Days': [moment().subtract(6, 'days'), moment()],
    'Last 30 Days': [moment().subtract(29, 'days'), moment()],
    'This Month': [moment().startOf('month'), moment().endOf('month')],
    'Last Month': [
      moment().subtract(1, 'month').startOf('month'),
      moment().subtract(1, 'month').endOf('month'),
    ],
  }
  useEffect(() => {
    ; (async () => {
      setFetchLoader(true)
      setSort('createdAt')
      setSortOrder(-1)
      await fetchLocation()
      await fetchData(
        page,
        search,
        { createdAt: -1 },
        dates.startDate,
        dates.endDate,
        currentLocation ? JSON.stringify(currentLocation) : '',
      )
      setFetchLoader(false)
    })()
  }, [])
  const fetchLocation = async () => {
    setFetchLoader(true)
    let apiService = new APICallService(EVENTLOCATION)
    let response = await apiService.callAPI()
    if (response) {
      setLocation(response.location)
    }
  }
  const fetchData = async (
    pageNumber,
    searchTerm,
    sort,
    startDate,
    endDate,
    currentLocation,
  ) => {
    setFetchLoader(true)
    if (Object.keys(sort).includes("eventStartTime")) {
      sort = { ["eventStartTime"]: sortOrder }
    }
    let apiService = new APICallService(
      LISTEVENTS,
      apiJSON.listEvents(
        PAGE_LIMIT,
        pageNumber,
        searchTerm,
        sort,
        startDate,
        endDate,
        currentLocation,
      ),
    )
    let response = await apiService.callAPI()
    let data = []
    if (response) {
      response.data.map((dataVal, index) => {
        data.push({
          [String.general._id]: dataVal._id,
          [String.general.index]: (pageNumber - 1) * PAGE_LIMIT + index + 1,
          [String.events.name]: dataVal.name,
          [String.events.description]: dataVal.description,
          ["eventDate"]: moment(dataVal.eventDate).format(
            'DD-MM-YYYY',
          ),
          ["eventStartTime"]:
            moment.unix(dataVal.eventStartTime).utc().local().format('HH:mm') +
            ' - ' +
            moment.unix(dataVal.eventEndTime).utc().local().format('HH:mm'),
          [String.general.action]: (
            <>
              <Link to={`/events/event-details/${dataVal._id}`}>
                <i className={'flaticon-eye icon-lg viewIcon'}></i>
              </Link>
            </>
          ),
        })
      })
      setTotalPages(response.total)
    }
    setPage(pageNumber)
    setInquiry(data)
  }
  const setDates = async (e, { startDate, endDate }) => {
    setDatesState({
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
    })
    await fetchData(
      page,
      search,
      { [sort]: sortOrder },
      startDate.format('YYYY-MM-DD'),
      endDate.format('YYYY-MM-DD'),
      currentLocation ? JSON.stringify(currentLocation) : '',
    )
    setFetchLoader(false)
  }
  const handlePageChange = async (page) => {
    await fetchData(
      page,
      search,
      { [sort]: sortOrder },
      dates.startDate,
      dates.endDate,
      currentLocation ? JSON.stringify(currentLocation) : '',
    )
    setFetchLoader(false)
  }
  const handleSearch = async (e) => {
    if (e.target.value.length) {
      setSearch(e.target.value)
      if (e.target.value.length >= 2) {
        await fetchData(
          1,
          e.target.value,
          { [sort]: sortOrder },
          dates.startDate,
          dates.endDate,
          currentLocation ? JSON.stringify(currentLocation) : '',
        )
        setPage(1)
      }
    } else {
      setSearch('')
      setPage(1)
      await fetchData(
        1,
        '',
        { [sort]: sortOrder },
        dates.startDate,
        dates.endDate,
        currentLocation ? JSON.stringify(currentLocation) : '',
      )
    }
    setFetchLoader(false)
  }
  const handleSort = async (e) => {
    let order = sortOrder
    setSort(e.selector)
    if (order === -1) {
      setSortOrder(1)
      await fetchData(
        1,
        search,
        { [e.selector]: 1 },
        dates.startDate,
        dates.endDate,
        currentLocation ? JSON.stringify(currentLocation) : '',
      )
    } else {
      setSortOrder(-1)
      await fetchData(
        1,
        search,
        { [e.selector]: -1 },
        dates.startDate,
        dates.endDate,
        currentLocation ? JSON.stringify(currentLocation) : '',
      )
    }
    setFetchLoader(false)
  }
  const handleCancel = async (page) => {
    setFetchLoader(true)
    setDatesState({
      startDate: '',
      endDate: '',
    })
    await fetchData(
      1,
      search,
      { [sort]: sortOrder },
      '',
      '',
      currentLocation ? JSON.stringify(currentLocation) : '',
    )
    setFetchLoader(false)
  }
  const handleLocation = async (e) => {
    if (e) {
      setCurrentLocation({
        type: 'Point',
        coordinates: e.value,
      })
      await fetchData(
        1,
        search,
        { [sort]: sortOrder },
        dates.startDate,
        dates.endDate,
        JSON.stringify({
          type: 'Point',
          coordinates: e.value,
        }),
      )
    } else {
      setCurrentLocation('')
      await fetchData(
        1,
        search,
        { [sort]: sortOrder },
        dates.startDate,
        dates.endDate,
        '',
      )
    }
    setFetchLoader(false)
  }
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-custom gutter-b example example-compact">
            <div className="card-header p-2 px-4 col-12 w-100 d-block">
              <div className="card-title row">
                <div className="col-lg-4">
                  <h3 className="card-label  mt-3 mb-2">
                    {String.events.title}
                  </h3>
                </div>
                <div className="col-lg-8 d-flex justify-content-end">
                  <Paper className="searchText">
                    <InputBase
                      className={' px-3'}
                      placeholder="Search"
                      inputProps={{ 'aria-label': 'Search Google Maps' }}
                      onChange={(e) => {
                        handleSearch(e, 1)
                      }}
                      name="name"
                    />
                    <IconButton
                      className=""
                      aria-label="Search"
                      onClick={() => {
                        handleSearch(search, 1)
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mt-3 d-flex justify-content-between">
                  <div className="col-lg-4">
                    <DateRangePicker
                      onApply={setDates}
                      initialSettings={{
                        startDate: moment().format('MM/DD/YYYY'),
                        endDate: moment().format('MM/DD/YYYY'),
                        ranges: ranges,
                      }}
                      onCancel={handleCancel}
                    >
                      <input
                        type="text"
                        value={
                          dates.startDate && dates.endDate
                            ? dates.startDate + ' - ' + dates.endDate
                            : 'Select Date'
                        }
                        className="form-control"
                      />
                    </DateRangePicker>
                  </div>
                  <div className="col-lg-4">
                    <Select
                      className="w-100"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? '#6959FC' : '#ebedf3',
                        }),
                      }}
                      options={
                        location ? (
                          location.map((value) => {
                            return {
                              value: value.coordinates,
                              label: value.city,
                              name: value.city,
                            }
                          })
                        ) : (
                          <></>
                        )
                      }
                      isClearable="true"
                      onChange={(e) => {
                        handleLocation(e)
                      }}
                      placeholder="Select Location"
                    ></Select>
                  </div>
                </div>
              </div>
              {/* <div className="separator separator-dashed my-7"></div> */}
            </div>
            <div className="card-body">
              {/* {fetchLoader === false ? (
                <CustomDatatable
                  columns={columns}
                  data={inquiry}
                  className="dataTables_wrapper"
                  responsive={true}
                  highlightOnHover={false}
                  pagination={true}
                  striped={true}
                  paginationServer
                  paginationTotalRows={totalPages}
                  paginationPerPage={PAGE_LIMIT}
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                  onChangePage={(page) => {
                    handlePageChange(page)
                  }}
                  subHeader={false}
                  noHeader={true}
                  // progressPending={loading}
                  progressComponent={
                    <div className="d-flex justify-content-center mb-5">
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: '#115DC4' }}
                      >
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  }
                  subHeaderComponent={<></>}
                  onSort={handleSort}
                  sortServer={false}
                />
              ) : (
                <>
                  <div className="card-body">
                    <div className="d-flex justify-content-center">
                      <Spinner
                        animation="border"
                        variant="primary"
                        style={{ height: '4em', width: '4em' }}
                      />
                    </div>
                  </div>
                </>
              )} */}
              <CustomDatatable
                columns={columns}
                data={inquiry}
                className="dataTables_wrapper"
                responsive={true}
                highlightOnHover={false}
                pagination={true}
                striped={true}
                paginationServer
                paginationTotalRows={totalPages}
                paginationPerPage={PAGE_LIMIT}
                paginationComponentOptions={{
                  noRowsPerPage: true,
                }}
                onChangePage={(page) => {
                  handlePageChange(page)
                }}
                subHeader={false}
                noHeader={true}
                progressPending={fetchLoader}
                progressComponent={
                  <div className="d-flex justify-content-center mb-5">
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ color: '#115DC4' }}
                    >
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                }
                subHeaderComponent={<></>}
                onSort={handleSort}
                sortServer={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})
export default connect(mapStateToProps)(ViewEvents)
