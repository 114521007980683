import React, { useState } from 'react'
/*eslint no-restricted-imports: ["error", { "paths": ["cluster"] }]*/
import clsx from 'clsx'
import { Button, Modal } from 'react-bootstrap'
import { String } from '../../utils/string'
import { ErrorMessage, Form, Formik } from 'formik'
import { createTheme, TextField, ThemeProvider } from '@mui/material'
import { EDITCUISINE } from '../../utils/constants'
import APICallService from '../../api/apiCallService'
import apiJSON from '../../api/apiJSON'
import { toast } from 'react-toastify'
const EditCuisine = (props) => {
  const [show, setShow] = useState(false)

  const [loading, setLoading] = useState(false)

  const initialState = {
    name: props.cuisineName,
    _id: props._id,
  }
  const [input, setInput] = useState(initialState)

  const handleChange = (event, name) => {
    let data = { ...input }
    data[name] = event.target.value
    setInput(data)
  }
  const theme7 = createTheme({
    palette: {
      primary: { main: '#6959FC' },
    },
  })
  const handleOnKeyPress = (event) => {
    var charCode = event.which ? event.which : event.keyCode
    if (
      !(charCode >= 65 && charCode <= 123) &&
      charCode !== 32 &&
      charCode !== 0
    ) {
      event.preventDefault()
      return false
    }
    return true
  }
  const handleEdit = async () => {
    setLoading(true)
    let apiService = new APICallService(
      EDITCUISINE,
      apiJSON.EditCuisine(input._id, input.name),
    )
    let response = await apiService.callAPI()

    setInput({ name: '' })
    toast.success(String.messages.cuisineEdited)

    props.reload()
    setShow(false)
    setLoading(false)
  }
  return (
    <>
      <i
        className={clsx('flaticon2-pen icon-lg icon mr-2')}
        onClick={() => {
          setShow(!show)
        }}
      ></i>

      <Modal
        show={show}
        onHide={() => {
          setShow(!show)
        }}
        size="md"
        centered
      >
        <Modal.Header>
          <Modal.Title>{String.cuisine.editTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-2">
          {' '}
          <Formik
            enableReinitialize={initialState}
            initialValues={input}
            onSubmit={handleEdit}
            validate={(values) => {
              let errors = {}
              if (Boolean(values?.name.trim()) === false) errors.name = '*'

              return errors
            }}
          >
            {({ touched, errors }) => (
              <div className="row">
                <div className="col-lg-12 mt-3">
                  <Form>
                    <div className="row ">
                      <div className="col-lg-12">
                        <div className="col-lg-6 float-left">
                          <div className={' float-left'}>
                            <p className="text-54 f-15 mb-2 font-weight-bold ml-1">
                              {String.cuisine.cuisineName}
                            </p>
                          </div>
                          <div className={' float-left'}>
                            <ErrorMessage
                              component="div"
                              name="name"
                              className="invalid-starModal"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row ml-n3">
                      <div className="col-lg-12">
                        <div className={'col-lg-12 float-left'}>
                          <ThemeProvider theme={theme7}>
                            <TextField
                              error={
                                Object.keys(touched).length &&
                                Object.keys(errors).length
                              }
                              sx={{
                                '.Mui-error ': {
                                  borderColor: ' #f64e60',
                                },

                                '& .MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderWidth: '1px !important',
                                  },
                                },
                                '& .Mui-error.MuiOutlinedInput-root': {
                                  '& fieldset': {
                                    borderColor: '#f64e60',
                                  },
                                  '&:hover fieldset': {
                                    borderColor: '#f64e60',
                                  },
                                  '&.Mui-focused fieldset': {
                                    borderColor: '#f64e60',
                                  },
                                },
                              }}
                              label=""
                              variant="outlined"
                              id="mui-theme-provider-outlined-input"
                              fullWidth
                              name="name"
                              value={input.name}
                              onChange={(e) => {
                                handleChange(e, 'name')
                              }}
                              onKeyPress={(e) => {
                                handleOnKeyPress(e, 'name')
                              }}
                            />
                          </ThemeProvider>
                        </div>
                      </div>
                    </div>

                    <div className="mt-4 mb-3 d-flex justify-content-end border-top border-#ebedf3  mr-1">
                      <Button
                        variant="secondary"
                        onClick={() => {
                          setShow(!show)
                        }}
                        className="mt-3 mr-2"
                      >
                        Close
                      </Button>
                      <Button
                        className="mt-3 mr-3 w-auto btn btn-primary font-weight-bold"
                        type="submit"
                      >
                        <span className="f-17">Save Changes</span>
                        {loading && (
                          <span className="ml-3 spinner spinner-white mr-4"></span>
                        )}
                      </Button>
                    </div>
                  </Form>
                </div>
              </div>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  )
}
export default EditCuisine
