import React, { useEffect, useState } from 'react'
import CustomDatatable from '../Global/Datatable'
import { String } from '../../../utils/string'
import APICallService from '../../../api/apiCallService'
import apiJSON from '../../../api/apiJSON'
import { AKHIEARNINGS, LISTREVENUE, PAGE_LIMIT } from '../../../utils/constants'
import { Spinner } from 'react-bootstrap'
import { connect } from 'react-redux'
import { IconButton, InputBase, Paper } from '@mui/material'
// import UserDetails from '../../modals/UserDetails'
import SearchIcon from '@mui/icons-material/Search'
import LightBox from '../Global/LightBox'
import moment from 'moment'
const ViewRevenue = () => {
  const [show, setShow] = useState(false)
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(false)
  const columns = [
    {
      name: String.general.index,
      selector: String.general.index,
      sortable: false,
    },
    {
      name: String.revenue.image,
      selector: String.revenue.thumbnail,
      cell: (row) =>
        row[String.revenue.thumbnail] ? (
          <img
            height="30px"
            width="30px"
            alt={row[String.revenue.thumbnail]}
            src={row[String.revenue.thumbnail]}
            style={{ height: '50px', width: '100px' }}
            onClick={(e) => {
              let image = []
              image.push(e.target.src)
              setImages(image)
              setShow(true)
            }}
          />
        ) : (
          <>No Image</>
        ),
      sortable: false,
    },
    {
      name: String.revenue.eventName,
      selector: String.revenue.name,
      sortable: true,
    },
    {
      name: String.revenue.eventDate,
      selector: "eventDate",
      sortable: true,
    },
    {
      name: String.revenue.price,
      selector: String.revenue.price,
      sortable: true,
    },
    {
      name: String.revenue.revenueEarned,
      selector: "revenueEarned",
      sortable: true,
    },
    {
      name: String.revenue.totalAmount,
      selector: "intialTotal",
      sortable: true,
    },
  ]
  const [fetchLoader, setFetchLoader] = useState(false)
  const [inquiry, setInquiry] = useState([])
  const [totalPages, setTotalPages] = useState()
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [sortOrder, setSortOrder] = useState(-1)
  const [sort, setSort] = useState('')
  const [earnings, setEarnings] = useState(0)
  useEffect(() => {
    ; (async () => {
      setFetchLoader(true)
      setSort('eventDate')
      setSortOrder(-1)
      await fetchEarnings()
      await fetchData(page, search, { eventDate: -1 })
      setFetchLoader(false)
    })()
  }, [])
  const fetchData = async (pageNumber, searchTerm, sort) => {
    setFetchLoader(true)
    setLoading(true)

    let apiService = new APICallService(
      LISTREVENUE,
      apiJSON.listGeneral(10, pageNumber, searchTerm, sort),
    )
    let response = await apiService.callAPI()
    let data = []
    setPage(pageNumber)
    if (response) {
      response.data.map((dataVal, index) => {
        data.push({
          [String.general.index]: (pageNumber - 1) * PAGE_LIMIT + index + 1,
          [String.revenue.name]: dataVal.name,
          [String.revenue.image]: dataVal.image,
          [String.revenue.thumbnail]: dataVal.thumbnail,
          ["eventDate"]: moment(dataVal.eventDate).format(
            'DD-MM-YYYY',
          ),
          [String.revenue.price]: '€  ' + dataVal.price.toFixed(2),
          ["intialTotal"]: '€  ' + dataVal.intialTotal.toFixed(2),
          ["revenueEarned"]: '€  ' + dataVal.revenueEarned.toFixed(2),
        })
      })
      setTotalPages(response.total)
    }
    setInquiry(data)
    setLoading(false)
  }
  const fetchEarnings = async () => {
    setFetchLoader(true)
    let apiService = new APICallService(AKHIEARNINGS)
    let response = await apiService.callAPI()
    setEarnings(response.akhieEarnings.toFixed(2))
  }
  const handlePageChange = async (page) => {
    fetchData(page, search, { [sort]: sortOrder })
    setFetchLoader(false)
  }
  const handleSearch = async (e) => {
    if (e.target.value.length) {
      setSearch(e.target.value)
      if (e.target.value.length >= 2) {
        await fetchData(1, e.target.value, { [sort]: sortOrder })
        setPage(1)
      }
    } else {
      setSearch('')
      setPage(1)
      await fetchData(1, '', { [sort]: sortOrder })
    }
    setFetchLoader(false)
  }
  const handleSort = async (e) => {
    let order = sortOrder
    setSort(e.selector)
    if (order === -1) {
      setSortOrder(1)
      await fetchData(1, search, { [e.selector]: 1 })
    } else {
      setSortOrder(-1)
      await fetchData(1, search, { [e.selector]: -1 })
    }
    setFetchLoader(false)
  }
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {show ? (
            <LightBox
              images={images}
              onHide={() => {
                setShow(false)
              }}
            />
          ) : (
            <></>
          )}
          <div className="card card-custom gutter-b example example-compact">
            <div className="card-header row">
              <div className="card-title col-lg">
                <h3 className="card-label">{String.revenue.title}</h3>
              </div>
              <div className="col-lg-auto align-self-center d-flex">
                <h5 className="card-label ">
                  {String.revenue.totalEarned}:- {earnings}
                </h5>
              </div>
              <div className="col-lg-auto align-self-center d-flex">
                <Paper className="searchText">
                  <InputBase
                    className={' px-3'}
                    placeholder="Search"
                    inputProps={{ 'aria-label': 'Search Google Maps' }}
                    onChange={(e) => {
                      handleSearch(e, 1)
                    }}
                    name="name"
                  />
                  <IconButton
                    className=""
                    aria-label="Search"
                    onClick={() => {
                      handleSearch(search, 1)
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>
              <div className="separator separator-dashed my-7"></div>
            </div>
            <div className="card-body">
              {/* {fetchLoader === false ? (
                <CustomDatatable
                  columns={columns}
                  data={inquiry}
                  className="dataTables_wrapper"
                  responsive={true}
                  highlightOnHover={false}
                  pagination={true}
                  striped={true}
                  paginationServer
                  paginationTotalRows={totalPages}
                  paginationPerPage={10}
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                  onChangePage={(page) => {
                    handlePageChange(page)
                  }}
                  subHeader={false}
                  noHeader={true}
                  progressPending={loading}
                  progressComponent={
                    <div className="d-flex justify-content-center mb-5">
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: '#115DC4' }}
                      >
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  }
                  subHeaderComponent={<></>}
                  onSort={handleSort}
                  sortServer={false}
                />
              ) : (
                <>
                  <div className="card-body">
                    <div className="d-flex justify-content-center">
                      <Spinner
                        animation="border"
                        variant="primary"
                        style={{ height: '4em', width: '4em' }}
                      />
                    </div>
                  </div>
                </>
              )} */}
              <CustomDatatable
                columns={columns}
                data={inquiry}
                className="dataTables_wrapper"
                responsive={true}
                highlightOnHover={false}
                pagination={true}
                striped={true}
                paginationServer
                paginationTotalRows={totalPages}
                paginationPerPage={10}
                paginationComponentOptions={{
                  noRowsPerPage: true,
                }}
                onChangePage={(page) => {
                  handlePageChange(page)
                }}
                subHeader={false}
                noHeader={true}
                progressPending={fetchLoader}
                progressComponent={
                  <div className="d-flex justify-content-center mb-5">
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ color: '#115DC4' }}
                    >
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                }
                subHeaderComponent={<></>}
                onSort={handleSort}
                sortServer={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})
export default connect(mapStateToProps)(ViewRevenue)
