import React, { useEffect, useState } from 'react'
import CustomDatatable from '../Global/Datatable'
import { String } from '../../../utils/string'
import APICallService from '../../../api/apiCallService'
import apiJSON from '../../../api/apiJSON'
import { LISTUSERS, PAGE_LIMIT } from '../../../utils/constants'
import { Spinner } from 'react-bootstrap'
import { connect } from 'react-redux'
import { IconButton, InputBase, Paper } from '@mui/material'
import UserDetails from '../../modals/UserDetails'
import DeleteUser from '../../modals/DeleteUser'
import ActiveUser from '../../modals/ActiveUser'
import SearchIcon from '@mui/icons-material/Search'
import LightBox from '../Global/LightBox'
import Select from 'react-select'
import { useParams } from 'react-router-dom';
const ViewUsers = () => {
  const columns = [
    {
      name: String.general.index,
      selector: String.general.index,
      sortable: false,
    },
    {
      name: String.users.profile,
      selector: String.users.profile,
      cell: (row) =>
        row[String.users.profile] ? (
          <>
            <img
              height="30px"
              width="30px"
              alt={row[String.users.profile]}
              src={row[String.users.profile]}
              style={{ height: '50px', width: '100px' }}
              onClick={(e) => {
                let image = []
                image.push(row['photo'])
                setImages(image)
                setShow(true)
              }}
            />
          </>
        ) : (
          <>No Profile Image</>
        ),
      sortable: false,
    },
    {
      name: String.users.phoneNumber,
      selector: "number",
      sortable: true,
    },
    {
      name: String.users.name,
      selector: String.cuisine.name,
      sortable: true,
      sortField: String.users.name,
    },
    {
      name: String.general.action,
      selector: String.general.action,
      sortable: false,
    },
  ]
  const { key } = useParams();
  const [show, setShow] = useState(false)
  const [userType, setUserType] = useState([1, 2])
  const [selectedUserType, setSelectedUserType] = useState(0)
  const [active, setActive] = useState([true, false])
  const [isDeleted, setDeleted] = useState([true, false])
  const [images, setImages] = useState([])
  const [fetchLoader, setFetchLoader] = useState(false)
  const [inquiry, setInquiry] = useState([])
  const [totalPages, setTotalPages] = useState()
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [sortOrder, setSortOrder] = useState(-1)
  const [sort, setSort] = useState('')
  useEffect(() => {
    ; (async () => {
      if (key !== 0)
        setSelectedUserType(parseInt(key))
      setFetchLoader(true)
      setSort('createdAt')
      setSortOrder(-1)
      await fetchData(
        page,
        search,
        { createdAt: -1 },
        [parseInt(key) !== 0 ? parseInt(key) : userType],
        active,
        isDeleted,
      )
      setFetchLoader(false)
    })()
  }, [])
  const fetchData = async (
    pageNumber,
    searchTerm,
    sort,
    userType,
    active,
    isDeleted,
  ) => {
    setFetchLoader(true)
    if (Object.keys(sort).includes("number")) {
      sort = { ["countryCode"]: sortOrder, ["phoneNumber"]: sortOrder }
    }
    let apiService = new APICallService(
      LISTUSERS,
      apiJSON.listUser(
        PAGE_LIMIT,
        pageNumber,
        searchTerm,
        sort,
        userType,
        active,
        isDeleted,
      ),
    )
    let response = await apiService.callAPI()
    let data = []
    if (response) {
      response.data.map((dataVal, index) => {
        let events = dataVal.events ? dataVal.events.events
          : 0
        let reservations = dataVal.reservations ? dataVal.reservations.reservations
          : 0
        data.push({
          [String.general.index]: (pageNumber - 1) * PAGE_LIMIT + index + 1,
          ["number"]:
            '+' + dataVal.countryCode + ' ' + dataVal.phoneNumber,
          [String.cuisine.name]: dataVal.name,
          [String.users.profile]: dataVal.thumbnail,
          photo: dataVal.photo,
          events: dataVal.events ? dataVal.events.events
            : 0,
          reservations: dataVal.reservations ? dataVal.reservations.reservations
            : 0,
          [String.general.action]: (
            <>
              <UserDetails details={dataVal} />
              <ActiveUser
                _id={dataVal._id}
                isActive={dataVal.isActive}
                reload={() => reloading(1)}
              />
              <DeleteUser
                _id={dataVal._id}
                isDelete={dataVal.isDeleted}
                reload={() => reloading(1)}
              />
            </>
          ),
        })
      })
      setTotalPages(response.total)
    }
    setPage(pageNumber)
    setInquiry(data)
  }
  const reloading = async () => {
    setFetchLoader(true)
    setPage(1)
    fetchData(page, search, { [sort]: sortOrder }, userType, active, isDeleted)
    setUserType([0, 1])
    setActive([true, false])
    setFetchLoader(false)
  }
  const handlePageChange = async (page) => {
    // console.log("[sort]: sortOrder ", { [sort]: sortOrder })
    fetchData(page, search, { [sort]: sortOrder }, userType, active, isDeleted)
    setFetchLoader(false)
  }
  const handleSearch = async (e) => {
    if (e.target.value.length) {
      setSearch(e.target.value)
      if (e.target.value.length >= 2) {
        await fetchData(
          1,
          e.target.value,
          { [sort]: sortOrder },
          userType,
          active,
          isDeleted,
        )
        setPage(1)
      }
    } else {
      setSearch('')
      setPage(1)
      await fetchData(1, '', { [sort]: sortOrder }, userType, active, isDeleted)
    }
    setFetchLoader(false)
  }
  const handleSort = async (e) => {
    let order = sortOrder
    if (order === -1) {
      setSortOrder(1)
      if (e.selector == "number") {
        setSort("number")
      } else {
        setSort(e.selector)
      }
      await fetchData(
        1,
        search,
        { [e.selector.toLowerCase()]: 1 },
        userType,
        active,
        isDeleted
      )
    } else {
      setSortOrder(-1)
      if (e.selector == "number") {
        setSort("number")
      } else {
        setSort(e.selector)
      }
      await fetchData(
        1,
        search,
        { [e.selector.toLowerCase()]: -1 },
        userType,
        active,
        isDeleted
      )
    }
    setFetchLoader(false)
  }
  const handleUserType = async (e) => {
    if (e) {
      setUserType([e.value])
      setSelectedUserType(e.value)
      await fetchData(
        1,
        search,
        { [sort]: sortOrder },
        [e.value],
        active,
        isDeleted,
      )
    } else {
      setUserType([1, 2])
      setSelectedUserType(null)
      await fetchData(
        1,
        search,
        { [sort]: sortOrder },
        [1, 2],
        active,
        isDeleted,
      )
    }
    setFetchLoader(false)
  }
  const handleUserActive = async (e) => {
    if (e) {
      setActive([e.value])
      await fetchData(
        1,
        search,
        { [sort]: sortOrder },
        userType,
        [e.value],
        isDeleted,
      )
    } else {
      setActive([true, false])
      await fetchData(
        1,
        search,
        { [sort]: sortOrder },
        userType,
        [true, false],
        isDeleted,
      )
    }
    setFetchLoader(false)
  }
  const handleUserDelete = async (e) => {
    if (e) {
      setDeleted([e.value])
      await fetchData(1, search, { [sort]: sortOrder }, userType, active, [
        e.value,
      ])
    } else {
      setDeleted([true, false])
      await fetchData(1, search, { [sort]: sortOrder }, userType, active, [
        true,
        false,
      ])
    }
    setFetchLoader(false)
  }
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {show ? (
            <LightBox
              images={images}
              onHide={() => {
                setShow(false)
              }}
            />
          ) : (
            <></>
          )}
          <div className="card card-custom gutter-b example example-compact">
            <div className="card-header p-2 px-4 col-12 w-100 d-block">
              <div className="card-title row">
                <div className="col-lg-4">
                  <h3 className="card-label  mt-3 mb-2">
                    {String.users.title}
                  </h3>
                </div>
                <div className="col-lg-8 d-flex justify-content-end">
                  <Paper className="searchText">
                    <InputBase
                      className={' px-3'}
                      placeholder="Search"
                      inputProps={{ 'aria-label': 'Search Google Maps' }}
                      onChange={(e) => {
                        handleSearch(e, 1)
                      }}
                      name="name"
                    />
                    <IconButton
                      className=""
                      aria-label="Search"
                      onClick={() => {
                        handleSearch(search, 1)
                      }}
                    >
                      <SearchIcon />
                    </IconButton>
                  </Paper>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mt-3 d-flex justify-content-between">
                  <div className="col-lg-4">
                    <Select
                      className="w-100"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? '#6959FC' : '#ebedf3',
                        }),
                      }}
                      options={userType.map((value) => {
                        return {
                          value: value,
                          label: value === 1 ? 'Host' : 'Guest',
                          name: value,
                        }
                      })}
                      isClearable="true"
                      onChange={(e) => {
                        handleUserType(e)
                      }}
                      value={
                        selectedUserType ? selectedUserType == 1 ? {
                          value: selectedUserType,
                          label: selectedUserType === 1 ? 'Host' : 'Guest',
                          name: selectedUserType,
                        } : {
                          value: selectedUserType,
                          label: selectedUserType === 1 ? 'Host' : 'Guest',
                          name: selectedUserType,
                        } : null
                      }
                      placeholder="Select User Type"
                    />
                  </div>
                  <div className="col-lg-4">
                    <Select
                      className="w-100"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? '#6959FC' : '#ebedf3',
                        }),
                      }}
                      options={active.map((value) => {
                        return {
                          value: value,
                          label: value ? 'Active' : 'Deactive',
                          name: value,
                        }
                      })}
                      isClearable="true"
                      onChange={(e) => {
                        handleUserActive(e)
                      }}
                      placeholder="Select User "
                    ></Select>
                  </div>
                  <div className="col-lg-4">
                    <Select
                      className="w-100"
                      styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: state.isFocused ? '#6959FC' : '#ebedf3',
                        }),
                      }}
                      options={isDeleted.map((value) => {
                        return {
                          value: value,
                          label: value ? 'Restore' : 'Delete  ',
                          name: value,
                        }
                      })}
                      isClearable="true"
                      onChange={(e) => {
                        handleUserDelete(e)
                      }}
                      placeholder="Select Deleted"
                    ></Select>
                  </div>
                </div>
              </div>
              {/* <div className="separator separator-dashed my-7"></div> */}
            </div>
            <div className="card-body">
              <CustomDatatable
                columns={columns}
                data={inquiry}
                className="dataTables_wrapper"
                responsive={true}
                highlightOnHover={false}
                pagination={true}
                striped={true}
                paginationServer
                paginationTotalRows={totalPages}
                paginationPerPage={PAGE_LIMIT}
                paginationComponentOptions={{
                  noRowsPerPage: true,
                }}
                onChangePage={(page) => {
                  handlePageChange(page)
                }}
                subHeader={false}
                noHeader={true}
                progressPending={fetchLoader}
                progressComponent={
                  <div className="d-flex justify-content-center mb-5">
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ color: '#115DC4' }}
                    >
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                }
                subHeaderComponent={<></>}
                onSort={handleSort}
                sortServer={false}
              />
              {/* {fetchLoader === false ? (
                <CustomDatatable
                  columns={columns}
                  data={inquiry}
                  className="dataTables_wrapper"
                  responsive={true}
                  highlightOnHover={false}
                  pagination={true}
                  striped={true}
                  paginationServer
                  paginationTotalRows={totalPages}
                  paginationPerPage={PAGE_LIMIT}
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                  onChangePage={(page) => {
                    handlePageChange(page)
                  }}
                  subHeader={false}
                  noHeader={true}
                  // progressPending={loading}
                  progressComponent={
                    <div className="d-flex justify-content-center mb-5">
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: '#115DC4' }}
                      >
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  }
                  subHeaderComponent={<></>}
                  onSort={handleSort}
                  sortServer={false}
                />
              ) : (
                <>
                  <div className="card-body">
                    <div className="d-flex justify-content-center">
                      <Spinner
                        animation="border"
                        variant="primary"
                        style={{ height: '4em', width: '4em' }}
                      />
                    </div>
                  </div>
                </>
              )} */}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})
export default connect(mapStateToProps)(ViewUsers)
