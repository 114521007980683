import React, { Suspense } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import ViewEvents from './ViewEvents'
import EventDetails from './EventDetails'
import {
  LayoutSplashScreen,
  ContentRoute,
} from '../../../_akhie_frontend/layout'

export default function contactUs() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from eCommerce root URL to /customers */
          <Redirect exact={true} from="/events" to="/events/view-events" />
        }
        <ContentRoute path="/events/view-events" component={ViewEvents} />
        <ContentRoute
          path="/events/event-details/:id"
          component={EventDetails}
        />
      </Switch>
    </Suspense>
  )
}
