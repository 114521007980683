export const PREF_TOKEN = 'bearerToken'
export const IS_INTRO = '/'
export const IS_LOGIN = 'Login'
// API BASE URL
// export const BASE_URL = 'http://89.233.108.35:9003/api/'
// export const BASE_URL = 'http://127.0.0.1:9004/'
export const BASE_URL = 'https://akhie.imperoserver.in/api/'
// export const BASE_URL = 'https://treats.imperoserver.in/api/'
export const PAGE_LIMIT = 10
// API TYPES
export const GET = 'GET'
export const GET_URL_PARAMS = 'GET_URL_PARAMS'
export const GET_ID_PARAMS = 'GET_ID_PARAMS'
export const GET_URL_ENCODED = 'GET_URL_ENCODED'
export const POST = 'POST'
export const POST_RAW = 'POST_RAW'
export const POST_FORM = 'POST_FORM'
export const POST_URL_ENCODED = 'POST_URL_ENCODED'
export const POST_URL_PARAMS = 'POST_URL_PARAMS'
export const PATCH_FORM = 'PATCH_FORM'
export const PATCH_URL_ENCODED = 'PATCH_URL_ENCODED'
export const DELETE = 'DELETE'
export const DELETE_URL_PARAMS = 'DELETE_URL_PARAMS'
export const DELETE_URL_ENCODED = 'DELETE_URL_ENCODED'
export const MULTI_PART_POST = 'MULTI_PART'
export const MULTI_PART_PATCH = 'MULTI_PART_PATCH'
// API END Points
/**---------------Authentication------------------------------ */
export const LOGIN = 'login' + ' ' + POST_RAW
export const LOGOUT = 'logout' + ' ' + POST_RAW
export const CONTACTUS = 'contactUs' + ' ' + POST_RAW
export const FORGOTPASSWORD = 'forgotPassword' + ' ' + POST_RAW
export const CHANGEPASSWORD = 'changePassword' + ' ' + POST_RAW
export const RESETPASSWORD = 'resetPassword' + ' ' + POST_RAW
export const DASHBOARD = 'dashboard' + ' ' + GET
/**---------------Settings------------------------------ */
export const APPSETTINGS = 'appSettings' + ' ' + GET
export const EDITAPPSETTINGS = 'editAppSettings' + ' ' + POST_RAW
export const ABOUTUS = 'aboutus' + ' ' + GET
export const EDITABOUTUS = 'editAboutus' + ' ' + POST_RAW
export const POLICY = 'privacyPolicy' + ' ' + GET
export const EDITPOLICY = 'editPrivacyPolicy' + ' ' + POST_RAW
/**---------------Cuisine------------------------------ */
export const LISTCUISINE = 'listCuisine' + ' ' + GET_URL_PARAMS
export const ADDCUISINE = 'addCuisine' + ' ' + POST_RAW
export const EDITCUISINE = 'editCuisine' + ' ' + POST_RAW
export const DELETECUISINE = 'deleteCuisine' + ' ' + DELETE
/**---------------Inquiry------------------------------ */
export const LISTINQUIRY = 'listContactus' + ' ' + GET_URL_PARAMS
/**---------------Users------------------------------ */
export const LISTUSERS = 'listUsers' + ' ' + GET_URL_PARAMS
export const DELETEUSER = 'deleteUser' + ' ' + DELETE
export const ACTIVEUSER = 'activeUser' + ' ' + POST_RAW
export const USERTRANSACTION = 'listTransferTransaction' + ' ' + GET_URL_PARAMS
/**---------------Events------------------------------ */
export const LISTEVENTS = 'listAllEvents' + ' ' + GET_URL_PARAMS
export const EVENTDETAILS = 'eventDetailsById' + ' ' + GET_URL_PARAMS
export const EVENTLOCATION = 'eventLocation' + ' ' + GET_URL_PARAMS
/**---------------Revenue------------------------------ */
export const LISTREVENUE = 'listRevenue' + ' ' + GET_URL_PARAMS
export const AKHIEARNINGS = 'akhieEarnings' + ' ' + GET_URL_PARAMS
/**---------------Reservation------------------------------ */
export const LISTRESERVATION = 'listAllReservationAdmin' + ' ' + GET_URL_PARAMS
//Response
export const ResponseFail = 0
export const ResponseSuccess = 200
export const AuthError = 401
export const MissingToken = 403
export const Not_found = 404
export const Server_error = 500
export const Maintenance = 503
