import React, { Suspense } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import ViewTransaction from './ViewTransaction'
import {
  LayoutSplashScreen,
  ContentRoute,
} from '../../../_akhie_frontend/layout'

export default function contactUs() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from eCommerce root URL to /customers */
          <Redirect
            exact={true}
            from="/transactions"
            to="/transactions/view-transactions"
          />
        }
        <ContentRoute
          path="/transactions/view-transactions"
          component={ViewTransaction}
        />
      </Switch>
    </Suspense>
  )
}
