import React, { useMemo } from 'react'
import { ReactComponent as Logo } from '../../../_assets/akhie_logo.svg'
import { useHtmlClassService } from '../../_core/AkhieLayoutProvider'
import { Link } from 'react-router-dom'

import AkhieLogo from '../../../_assets/logo.png'

export function Brand() {
  const uiService = useHtmlClassService()

  const layoutProps = useMemo(() => {
    return {
      brandClasses: uiService.getClasses('brand', true),
      headerLogo: uiService.getLogo(),
      headerStickyLogo: uiService.getStickyLogo(),
    }
  }, [uiService])

  return (
    <>
      {/* begin::Brand */}
      <div
        className={`brand flex-column-auto ${layoutProps.brandClasses}`}
        id="kt_brand"
      >
        {/* begin::Logo */}

        <Link to="" className="brand-logo">
          <img
            className="img-fluid"
            width={164}
            // height={65}
            src={AkhieLogo}
            alt="akhie logo"
          />
          {/* <Logo style={{ stroke: "#6959FC", height: "65px" }} /> */}
        </Link>
        {/* <span className="mt-10" style={{ color: '#9AADF2' }}>
          <Logo style={{ stroke: '#6959FC', height: '65px' }} /> EverGlow
        </span> */}
        {/* end::Logo */}
      </div>
      {/* end::Brand */}
    </>
  )
}
