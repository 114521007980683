/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link, Switch, Redirect } from 'react-router-dom'
import { toAbsoluteUrl } from '../../../../_akhie_frontend/_helpers'
import { ContentRoute } from '../../../../_akhie_frontend/layout'
import Login from './Login'
import ResetPassword from './ResetPassword'
import ForgotPassword from './ForgotPassword'
import '../../../../_akhie_frontend/_assets/sass/pages/login/classic/login-1.scss'

export function AuthPage() {
  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-row-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Content*/}
          <div
            className="flex-row-fluid d-flex flex-column position-relative p-7 overflow-hidden"
            style={{ background: 'whitesmoke' }}
          >
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/reset-password"
                  component={ResetPassword}
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                {/* <ContentRoute path="/auth/verify-code" component={VerifyCode} /> */}
                {/* <ContentRoute
                  path="/auth/changePassword"
                  component={ChangePassword}
                /> */}

                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}

            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  )
}
