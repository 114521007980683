const apiJSON = {
  Login: (email, password, deviceId) => {
    return {
      email: email,
      password: password,
    }
  },
  ContactUs: (email, message) => {
    return {
      email: email,
      message: message,
    }
  },
  ForgotPassword: (email) => {
    return {
      email: email,
      userType: 0,
    }
  },
  AppSettings: (
    androidForceUpdate,
    androidVersion,
    iosForceUpdate,
    iosVersion,
    underMaintanance,
    _id,
  ) => {
    return {
      androidForceUpdate: androidForceUpdate,
      androidVersion: androidVersion,
      iosForceUpdate: iosForceUpdate,
      iosVersion: iosVersion,
      underMaintanance: underMaintanance,
      _id: _id,
    }
  },
  content: (content) => {
    return {
      content: content,
    }
  },
  listGeneral: (perPage, pageNo, searchTerm, sortBy) => {
    return {
      perPage: perPage,
      pageNo: pageNo,
      searchTerm: searchTerm,
      sortBy: JSON.stringify(sortBy),
    }
  },

  AddCuisine: (name) => {
    return {
      name: name,
    }
  },
  EditCuisine: (_id, name) => {
    return {
      _id: _id,
      name: name,
    }
  },
  DeleteCuisine: (cuisineId) => {
    return {
      cuisineId: cuisineId,
    }
  },
  DeleteUser: (userId, isDelete) => {
    return {
      userId: userId,
      isDelete: isDelete,
    }
  },
  ActiveUser: (userId, isActive) => {
    return {
      userId: userId,
      isActive: isActive,
    }
  },
  ChangePassword: (currentPassword, password) => {
    return { currentPassword: currentPassword, password: password }
  },
  ResetPassword: (email, password, code) => {
    return { email: email, password: password, code: code, userType: 0 }
  },
  listUser: (
    perPage,
    pageNo,
    searchTerm,
    sortBy,
    userType,
    active,
    isDeleted,
  ) => {
    return {
      perPage: perPage,
      pageNo: pageNo,
      searchTerm: searchTerm,
      sortBy: JSON.stringify(sortBy),
      userType: JSON.stringify(userType),
      active: JSON.stringify(active),
      isDeleted: JSON.stringify(isDeleted),
    }
  },
  listEvents: (
    perPage,
    pageNo,
    searchTerm,
    sortBy,
    startDate,
    endDate,
    location,
  ) => {
    return {
      perPage: perPage,
      pageNo: pageNo,
      searchTerm: searchTerm,
      sortBy: JSON.stringify(sortBy),
      startDate: startDate,
      endDate: endDate,
      location: location,
    }
  },
  eventDetails: (eventId) => {
    return {
      eventId: eventId,
    }
  },
  listReservation: (
    perPage,
    pageNo,
    searchTerm,
    sortBy,
    type,
    // active,
    time,
  ) => {
    return {
      perPage: perPage,
      pageNo: pageNo,
      searchTerm: searchTerm,
      sortBy: JSON.stringify(sortBy),
      type: type?type:0,
   
      time: time?time:"",
      
    }
  },
}
export default apiJSON
