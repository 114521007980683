import React, { Fragment } from 'react'
import { connect } from 'react-redux'
import DataTable from 'react-data-table-component'
const CustomDatatable = (props) => {
  const customStyles = {
    table: {
      style: {
        border: '1px solid #ebedf3',
      },
    },
    rows: {
      style: {
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '24px',
        color: '#252525',
        // height: '60px',
        minHeight: props.minHeight ? props.minHeight : "60px",
        wordBreak: 'break-word',

        alignItems: 'center',
        // backgroundColor: '#384160',
      },
      denseStyle: {
        minHeight: '32px',
      },
      stripedStyle: {
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '24px',
        color: '#252525',
        // backgroundColor: '#0f121d',
      },
    },
    header: {
      style: {
        fontSize: '22px',
        // color: theme.text.primary,
        // backgroundColor: theme.background.default,
        minHeight: '56px',
        paddingLeft: '16px',
        paddingRight: '8px',
      },
    },
    subHeader: {
      style: {
        fontSize: '26px',
        fontWeight: 700,
        color: '#FFFFFF',
        // backgroundColor: '#0f121d',
        fontFamily: 'Poppins',
        minHeight: '56px',
        paddingLeft: '0px',
        paddingRight: '8px',
        // justifyContent: "space-between",
      },
    },
    headRow: {
      style: {
        fontSize: '14px',
        color: '#C4C6D0',
        // backgroundColor: '#0f121d',
        fontWeight: 700,
        // borderColor: "#0f121d",
      },
    },
    cells: {
      style: {
        wordBreak: 'break-word',
        maxHeight: '50px',
      },
    },
    pagination: {
      style: {
        // color: "white",
        fontSize: '13px',
        minHeight: '56px',
        // backgroundColor: '#0f121d',
        borderTopStyle: 'solid',
        borderTopWidth: '1px',
        // borderTopColor: theme.divider.default,
      },
      pageButtonsStyle: {
        // color: "white",
        // fill: "white",
        '&:disabled': {
          // color: "white",
          // fill: "white",
        },
        '&:hover:not(:disabled)': {
          // color: "white",
          // fill: "white",
          // backgroundColor: '#0f121d',
        },
        '&:focus': {
          // color: "white",
          // backgroundColor: '#0f121d',
          // fill: "white",
        },
        menuItem: {
          '&:hover': {
            // backgroundColor: '#3b3f58',
          },
        },
      },
    },
    noData: {
      style: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: '#545454',
        // backgroundColor: '#0f121d',
        fontWeight: 600,
        fontSize: '14px',
        lineHeight: '24px',
      },
    },
  }
  return (
    <Fragment>
      <DataTable
        className="dataTables_wrapper"
        responsive={true}
        columns={props.columns}
        data={props.data}
        highlightOnHover={props.false}
        pagination
        striped={true}
        paginationServer
        noHeader={props.noHeader}
        subHeader={props.subHeader}
        paginationTotalRows={props.paginationTotalRows}
        paginationPerPage={props.paginationPerPage}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        progressPending={props.progressPending}
        progressComponent={props.progressComponent}
        currentPage={props.currentPage}
        onChangePage={props.onChangePage}
        onSort={props.onSort}
        sortServer
        customStyles={customStyles}
      />
    </Fragment>
  )
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})
export default connect(mapStateToProps)(CustomDatatable)
