import React, { useState } from 'react'
/*eslint no-restricted-imports: ["error", { "paths": ["cluster"] }]*/
import clsx from 'clsx'
import { Modal } from 'react-bootstrap'
import { String } from '../../utils/string'

const UserDetails = (props) => {
  const [show, setShow] = useState(false)
  const handleClose = () => setShow(false)
  const [input, setInput] = useState(props.details)

  return (
    <>
      <i
        className={'flaticon-eye icon-lg viewIcon'}
        // onClick={fetchData}
        onClick={() => {
          setShow(true)
        }}
      ></i>
      <>
        <Modal show={show} onHide={handleClose} size="md" centered>
          <Modal.Header closeButton>
            <Modal.Title>{String.users.titleDetail}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-12 modal-view">
                <p>
                  <span className="font-weight-bold fs-14">
                    {String.users.email} :-
                  </span>
                  <span>{props.details.email}</span>
                </p>
                <p>
                  <span className="font-weight-bold fs-14">
                    {String.users.type} :-{' '}
                  </span>
                  <span>
                    {Object.values(props.details.userType).includes(2) &&
                    Object.values(props.details.userType).includes(1)
                      ? 'Host/Guest'
                      : Object.values(props.details.userType).includes(2)
                      ? 'Guest'
                      : 'Host'}
                  </span>
                </p>
                {Object.values(props.details.userType).includes(2) &&
                Object.values(props.details.userType).includes(1) ? (
                  <p>
                    <span className="font-weight-bold fs-14">
                      {String.users.totalReview} :-{' '}
                    </span>
                    <span>{props.details.totalReview}</span>
                  </p>
                ) : Object.values(props.details.userType).includes(1) ? (
                  <p>
                    <span className="font-weight-bold fs-14">
                      {String.users.totalReview} :-{' '}
                    </span>
                    <span>{props.details.totalReview}</span>
                  </p>
                ) : (
                  <></>
                )}
                {Object.values(props.details.userType).includes(2) &&
                Object.values(props.details.userType).includes(1) ? (
                  <p>
                    <span className="font-weight-bold fs-14">
                      {String.users.averageReview} :-{' '}
                    </span>
                    <span>
                      {props.details.averageReview
                        ? props.details.averageReview
                        : 0}
                    </span>
                  </p>
                ) : Object.values(props.details.userType).includes(1) ? (
                  <p>
                    <span className="font-weight-bold fs-14">
                      {String.users.averageReview} :-{' '}
                    </span>
                    <span>
                      {props.details.averageReview
                        ? props.details.averageReview
                        : 0}
                    </span>
                  </p>
                ) : (
                  <></>
                )}
                {Object.values(props.details.userType).includes(2) &&
                Object.values(props.details.userType).includes(1) ? (
                  <p>
                    <span className="font-weight-bold fs-14">
                      {String.users.reservations} :-{' '}
                    </span>
                    <span>
                      {props.details.reservations
                        ? props.details.reservations.reservations
                        : 0}
                    </span>
                  </p>
                ) : Object.values(props.details.userType).includes(2) ? (
                  <p>
                    <span className="font-weight-bold fs-14">
                      {String.users.reservations} :-{' '}
                    </span>
                    <span>
                      {props.details.reservations
                        ? props.details.reservations.reservations
                        : 0}
                    </span>
                  </p>
                ) : (
                  <></>
                )}
                {Object.values(props.details.userType).includes(2) &&
                Object.values(props.details.userType).includes(1) ? (
                  <p>
                    <span className="font-weight-bold fs-14">
                      {String.users.reviewGiven} :-{' '}
                    </span>
                    <span>
                      {props.details.reviews
                        ? props.details.reviews.reviews
                        : 0}
                    </span>
                  </p>
                ) : Object.values(props.details.userType).includes(2) ? (
                  <p>
                    <span className="font-weight-bold fs-14">
                      {String.users.reviewGiven} :-{' '}
                    </span>
                    <span>
                      {props.details.reviews
                        ? props.details.reviews.reviews
                        : 0}
                    </span>
                  </p>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    </>
  )
}
export default UserDetails
