import React, { useEffect, useState } from 'react'
import CustomDatatable from '../Global/Datatable'
import AddCuisine from './AddCuisine'
import { KTCodeExample } from '../../../_akhie_frontend/_partials/controls/'
import { String } from '../../../utils/string'
import APICallService from '../../../api/apiCallService'
import apiJSON from '../../../api/apiJSON'
import { LISTCUISINE, PAGE_LIMIT } from '../../../utils/constants'
import { Spinner } from 'react-bootstrap'
import { connect } from 'react-redux'
import EditCuisine from '../../modals/EditCuisine'
import DeleteCuisine from '../../modals/DeleteCuisine'
import { IconButton, InputBase, Paper } from '@mui/material'

import SearchIcon from '@mui/icons-material/Search'

const ViewCuisine = () => {
  const columns = [
    {
      name: String.general.index,
      selector: String.general.index,
      sortable: false,
    },
    {
      name: String.cuisine.cuisineName,
      selector: String.cuisine.name,
      sortable: true,
    },

    {
      name: String.general.action,
      selector: String.general.action,
    },
  ]
  const [fetchLoader, setFetchLoader] = useState(false)
  const [cuisine, setCuisine] = useState([])
  const [totalPages, setTotalPages] = useState()
  const [page, setPage] = useState(1)
  const [reload, setReload] = useState(false)
  const [sortOrder, setSortOrder] = useState(-1)
  const [sort, setSort] = useState('')
  const [search, setSearch] = useState('')

  useEffect(() => {
    ;(async () => {
      setSort('createdAt')
      setSortOrder(-1)
      await fetchData(page, search, { createdAt: -1 })

      setFetchLoader(false)
    })()
  }, [])
  const fetchData = async (pageNumber, searchTerm, sort) => {
    // setFetchLoader(true)
    let apiService = new APICallService(
      LISTCUISINE,
      apiJSON.listGeneral(PAGE_LIMIT, pageNumber, searchTerm, sort),
    )
    let response = await apiService.callAPI()
    let data = []
    if (response.length > 0) {
      response.map((val) => {
        val.data.map((dataVal, index) => {
          data.push({
            [String.general.index]: (pageNumber - 1) * PAGE_LIMIT + index + 1,
            [String.cuisine.name]: dataVal.name,
            [String.general.action]: (
              <>
                {dataVal.events === 0 ? (
                  <>
                    <EditCuisine
                      _id={dataVal._id}
                      cuisineName={dataVal.name}
                      reload={() => reloading(1)}
                      disable={true}
                    />
                    <DeleteCuisine
                      _id={dataVal._id}
                      reload={() => reloading(1)}
                    />
                  </>
                ) : (
                  <>-</>
                )}
              </>
            ),
            [String.general._id]: dataVal._id,
          })
        })
      })

      setTotalPages(response[0].pagination[0].total)
    }
    setPage(pageNumber)

    setCuisine(data)
  }
  const reloading = async () => {
    setFetchLoader(true)
    setPage(1)
    await fetchData(1, search, { [sort]: sortOrder })

    setFetchLoader(false)
  }
  const handlePageChange = async (page) => {
    fetchData(page, search, { [sort]: sortOrder })
    setFetchLoader(false)
  }
  const handleSort = async (e) => {
    let order = sortOrder
    if (order === -1) {
      setSortOrder(1)

      await fetchData(1, search, { [e.selector.toLowerCase()]: 1 })
    } else {
      setSortOrder(-1)
      await fetchData(1, search, { [e.selector.toLowerCase()]: -1 })
    }
    setFetchLoader(false)
  }
  const handleSearch = async (e) => {
    if (e.target.value.length) {
      setSearch(e.target.value)
      if (e.target.value.length >= 2) {
        await fetchData(1, e.target.value, { [sort]: sortOrder })
        setPage(1)
      }
    } else {
      setSearch('')
      setPage(1)
      await fetchData(1, '', { [sort]: sortOrder })
    }
    setFetchLoader(false)
  }
  return (
    <>
      <AddCuisine reload={() => reloading(1)} />
      <div className="row">
        <div className="col-md-12">
          <div className="card card-custom gutter-b example example-compact">
            <div className="card-header row">
              <div className="card-title col-lg">
                <h3 className="card-label">{String.inquiry.title}</h3>
              </div>
              <div className="col-lg-auto align-self-center d-flex">
                <Paper className="searchText">
                  <InputBase
                    className={' px-3'}
                    placeholder="Search"
                    inputProps={{ 'aria-label': 'Search Google Maps' }}
                    onChange={(e) => {
                      handleSearch(e, 1)
                    }}
                    name="name"
                  />
                  <IconButton
                    className=""
                    aria-label="Search"
                    onClick={() => {
                      handleSearch(search, 1)
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>
              <div className="separator separator-dashed my-7"></div>
            </div>
            <div className="card-body">
              {/* {fetchLoader === false ? (
                <CustomDatatable
                  columns={columns}
                  data={cuisine}
                  className="dataTables_wrapper"
                  responsive={true}
                  highlightOnHover={false}
                  pagination={true}
                  striped={true}
                  paginationServer
                  paginationTotalRows={totalPages}
                  paginationPerPage={PAGE_LIMIT}
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                  onChangePage={(page) => {
                    handlePageChange(page)
                  }}
                  subHeader={false}
                  noHeader={true}
                  // progressPending={loading}
                  progressComponent={
                    <div className="d-flex justify-content-center mb-5">
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: '#115DC4' }}
                      >
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  }
                  subHeaderComponent={<></>}
                  onSort={handleSort}
                  sortServer={false}
                />
              ) : (
                <>
                  <div className="card-body">
                    <div className="d-flex justify-content-center">
                      <Spinner
                        animation="border"
                        variant="primary"
                        style={{ height: '4em', width: '4em' }}
                      />
                    </div>
                  </div>
                </>
              )} */}
              <CustomDatatable
                columns={columns}
                data={cuisine}
                className="dataTables_wrapper"
                responsive={true}
                highlightOnHover={false}
                pagination={true}
                striped={true}
                paginationServer
                paginationTotalRows={totalPages}
                paginationPerPage={PAGE_LIMIT}
                paginationComponentOptions={{
                  noRowsPerPage: true,
                }}
                onChangePage={(page) => {
                  handlePageChange(page)
                }}
                subHeader={false}
                noHeader={true}
                progressPending={fetchLoader}
                progressComponent={
                  <div className="d-flex justify-content-center mb-5">
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ color: '#115DC4' }}
                    >
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                }
                subHeaderComponent={<></>}
                onSort={handleSort}
                sortServer={false}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})

export default connect(mapStateToProps)(ViewCuisine)
