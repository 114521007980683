import React, { useEffect, useState } from 'react'
import CustomDatatable from '../Global/Datatable'
import { String } from '../../../utils/string'
import APICallService from '../../../api/apiCallService'
import apiJSON from '../../../api/apiJSON'
import { LISTINQUIRY, PAGE_LIMIT } from '../../../utils/constants'
import { Spinner } from 'react-bootstrap'
import { connect } from 'react-redux'
import { IconButton, InputBase, Paper, Button } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import SearchIcon from '@mui/icons-material/Search'
import CloseIcon from '@mui/icons-material/Close'
import Typography from '@mui/material/Typography'
import { styled } from '@mui/material/styles'
const ViewContactUs = () => {
  const columns = [
    {
      name: String.general.index,
      selector: String.general.index,
      sortable: false,
    },
    {
      name: String.inquiry.email,
      selector: String.inquiry.email,
      sortable: true,
    },
    {
      name: String.inquiry.message,
      selector: String.inquiry.message,
      sortable: true,
    },
  ]
  const [fetchLoader, setFetchLoader] = useState(false)
  const [inquiry, setInquiry] = useState([])
  const [totalPages, setTotalPages] = useState()
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [sortOrder, setSortOrder] = useState(-1)
  const [sort, setSort] = useState('')
  const [open5, setOpen5] = React.useState(false)
  const [click, setClick] = useState(false)
  const [description, setDescription] = useState('')
  const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }))
  useEffect(() => {
    ; (async () => {
      setFetchLoader(true)
      setSort('createdAt')
      setSortOrder(-1)
      await fetchData(page, search, { createdAt: -1 })
      setFetchLoader(false)
    })()
  }, [])
  const fetchData = async (pageNumber, searchTerm, sort) => {
    setFetchLoader(true)
    let apiService = new APICallService(
      LISTINQUIRY,
      apiJSON.listGeneral(PAGE_LIMIT, pageNumber, searchTerm, sort),
    )
    let response = await apiService.callAPI()
    let data = []
    if (response) {
      response.data.map((dataVal, index) => {
        {
          console.log(dataVal.message.length)
        }
        data.push({
          [String.general.index]: (pageNumber - 1) * PAGE_LIMIT + index + 1,
          [String.inquiry.email]: dataVal.email,
          // [String.inquiry.message]: dataVal.message,
          [String.inquiry.message]:
            dataVal.message.length > 0 ? (
              dataVal.message.length > 30 ? (
                <>
                  {/* <div
                    dangerouslySetInnerHTML={{
                      __html: dataVal.message.substring(0, 30) + '...',
                    }}
                    style={{ whiteSpace: 'pre-wrap' }}
                  ></div>
                  <Button
                    onClick={() => {
                      handleClick(dataVal.message)
                    }}
                    variant="text"
                    color="secondary"
                  >
                    Read More
                  </Button> */}
                  <div
                    dangerouslySetInnerHTML={{
                      __html: dataVal.message.substring(0, 100),
                    }} style={{ whiteSpace: "normal" }}
                  ></div>
                  {dataVal.message.length > 100 ? (
                    <Button
                      onClick={() => {
                        handleClick(dataVal.message);
                      }}
                      color="secondary"
                      className='btn btn-link h-5px bg-null'
                    >
                      Read More
                    </Button>
                  ) : null}
                </>
              ) : (
                <>{dataVal.message}</>
              )
            ) : (
              '-'
            ),
        })
      })
      setTotalPages(response.total)
    }
    setPage(pageNumber)
    setInquiry(data)
  }
  const handlePageChange = async (page) => {
    fetchData(page, search, { [sort]: sortOrder })
    setFetchLoader(false)
  }
  const handleSearch = async (e) => {
    if (e.target.value.length) {
      setSearch(e.target.value)
      if (e.target.value.length >= 2) {
        await fetchData(1, e.target.value, { [sort]: sortOrder })
        setPage(1)
      }
    } else {
      setSearch('')
      setPage(1)
      await fetchData(1, '', { [sort]: sortOrder })
    }
    setFetchLoader(false)
  }
  const handleSort = async (e) => {
    let order = sortOrder
    if (order === -1) {
      setSortOrder(1)
      await fetchData(1, search, { [e.selector.toLowerCase()]: 1 })
    } else {
      setSortOrder(-1)
      await fetchData(1, search, { [e.selector.toLowerCase()]: -1 })
    }
    setFetchLoader(false)
  }
  const handleClose5 = () => {
    setOpen5(false)
  }
  const handleClick = async (value) => {
    setDescription(value)
    setClick(true)
    setOpen5(true)
  }
  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="card card-custom gutter-b example example-compact">
            <div className="card-header row">
              <div className="card-title col-lg">
                <h3 className="card-label">{String.inquiry.title}</h3>
              </div>
              <div className="col-lg-auto align-self-center d-flex">
                <Paper className="searchText">
                  <InputBase
                    className={' px-3'}
                    placeholder="Search"
                    inputProps={{ 'aria-label': 'Search Google Maps' }}
                    onChange={(e) => {
                      handleSearch(e, 1)
                    }}
                    name="name"
                  />
                  <IconButton
                    className=""
                    aria-label="Search"
                    onClick={() => {
                      handleSearch(search, 1)
                    }}
                  >
                    <SearchIcon />
                  </IconButton>
                </Paper>
              </div>
              <div className="separator separator-dashed my-7"></div>
            </div>
            <div className="card-body">
              {/* {fetchLoader === false ? (
                <CustomDatatable
                  columns={columns}
                  data={inquiry}
                  className="dataTables_wrapper"
                  responsive={true}
                  minHeight="100px"
                  highlightOnHover={false}
                  pagination={true}
                  striped={true}
                  paginationServer
                  paginationTotalRows={totalPages}
                  paginationPerPage={PAGE_LIMIT}
                  paginationComponentOptions={{
                    noRowsPerPage: true,
                  }}
                  onChangePage={(page) => {
                    handlePageChange(page)
                  }}
                  subHeader={false}
                  noHeader={true}
                  // progressPending={loading}
                  progressComponent={
                    <div className="d-flex justify-content-center mb-5">
                      <Spinner
                        animation="border"
                        role="status"
                        style={{ color: '#115DC4' }}
                      >
                        <span className="sr-only">Loading...</span>
                      </Spinner>
                    </div>
                  }
                  subHeaderComponent={<></>}
                  onSort={handleSort}
                  sortServer={false}
                />
              ) : (
                <>
                  <div className="card-body">
                    <div className="d-flex justify-content-center">
                      <Spinner
                        animation="border"
                        variant="primary"
                        style={{ height: '4em', width: '4em' }}
                      />
                    </div>
                  </div>
                </>
              )} */}
              <CustomDatatable
                columns={columns}
                data={inquiry}
                className="dataTables_wrapper"
                responsive={true}
                minHeight="100px"
                highlightOnHover={false}
                pagination={true}
                striped={true}
                paginationServer
                paginationTotalRows={totalPages}
                paginationPerPage={PAGE_LIMIT}
                paginationComponentOptions={{
                  noRowsPerPage: true,
                }}
                onChangePage={(page) => {
                  handlePageChange(page)
                }}
                subHeader={false}
                noHeader={true}
                progressPending={fetchLoader}
                progressComponent={
                  <div className="d-flex justify-content-center mb-5">
                    <Spinner
                      animation="border"
                      role="status"
                      style={{ color: '#115DC4' }}
                    >
                      <span className="sr-only">Loading...</span>
                    </Spinner>
                  </div>
                }
                subHeaderComponent={<></>}
                onSort={handleSort}
                sortServer={false}
              />
            </div>
            {click ? (
              <>
                <BootstrapDialog
                  onClose={handleClose5}
                  aria-labelledby="customized-dialog-title"
                  open={open5}
                  maxWidth="sm"
                  fullWidth={true}
                >
                  <div className="col-lg-12">
                    <div className="row">
                      <div className="col-lg-6 float-left">
                        <DialogTitle
                          id="customized-dialog-title"
                          className="MuiDialogTitle-root px-3"
                        >
                          Message
                        </DialogTitle>
                      </div>
                      <div className="col-lg-6 float-left d-flex align-items-center justify-content-end">
                        <DialogTitle
                          className="py-0"
                          id="customized-dialog-title"
                        >
                          <Button
                            onClick={handleClose5}
                            color="secondary"
                            variant="text"
                          >
                            Read Less
                          </Button>
                        </DialogTitle>
                      </div>
                    </div>
                  </div>
                  <DialogContent className="ml-3" dividers>
                    <Typography gutterBottom>
                      <div
                        style={{ whiteSpace: 'normal' }}
                        dangerouslySetInnerHTML={{
                          __html: description,
                        }}
                      ></div>
                    </Typography>
                  </DialogContent>
                </BootstrapDialog>
              </>
            ) : null}
          </div>
        </div>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})
export default connect(mapStateToProps)(ViewContactUs)
