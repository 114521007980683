import React, { useState } from 'react'

import { Dropdown } from 'react-bootstrap'
import { connect } from 'react-redux'
import { logoutUser } from '../../../../../app/actions/authActions'
import APICallService from '../../../../../api/apiCallService'
import { LOGOUT } from '../../../../../utils/constants'
import { toAbsoluteUrl } from '../../../../_helpers'
import { DropdownTopbarItemToggler } from '../../../../_partials/dropdowns'
import Avatar from '@mui/material/Avatar'
import ChangePassword from '../../../../../app/modals/ChangePassword'
const UserProfileDropdown = (props) => {
  const [changePassword, setChangePassword] = useState(false)
  const [loading, setLoading] = useState(false)
  const handleLogout = async () => {
    setLoading(true)
    let apiService = new APICallService(LOGOUT)
    await apiService.callAPI()
    props.logoutUser()
  }
  const handleChangePassword = () => {
    setChangePassword(!changePassword)
  }
  return (
    <Dropdown drop="down" alignRight>
      <Dropdown.Toggle
        as={DropdownTopbarItemToggler}
        id="dropdown-toggle-user-profile"
      >
        <div className={'d-flex justify-content-end'}>
          <span className="symbol symbol-45">
            <span className="symbol-label text-white font-size-h5 font-weight-bold bg-white-o-30 mr-15">
              <span
                className="font-size-h5 font-weight-bold mr-1"
                style={{ color: '#6959FC' }}
              >
                Welcome,
              </span>
              <span
                className="font-size-h5 font-weight-bold mr-2"
                style={{ color: '#6959FC' }}
              >
                Admin
              </span>{' '}
              <Avatar
                className={'mr-2'}
                sx={{ color: '#fff', backgroundColor: '#6959FC' }}
              >
                A
              </Avatar>
            </span>
          </span>
        </div>
      </Dropdown.Toggle>
      <Dropdown.Menu className="mr-auto dropdown-menu p-0 m-0 dropdown-menu-right dropdown-menu-anim-up dropdown-menu-sm p-0">
        <>
          <div className="navi navi-spacer-x-0 pt-1">
            <a className="navi-item px-3">
              <div className="navi-link">
                <div className="navi-icon mr-2">
                  <img
                    className="img-fluid w-75"
                    alt="user"
                    src={toAbsoluteUrl('/media/others/password.svg')}
                  ></img>
                </div>
                <div className="navi-text">
                  <div className="font-weight-bold">
                    <span
                      className="font-weight-bold"
                      onClick={handleChangePassword}
                    >
                      Change Password
                    </span>
                  </div>
                </div>
              </div>
            </a>

            <div className="navi-separator mt-3"></div>

            <div className="navi-footer d-flex justify-content-center px-8 py-2">
              <button
                className="btn btn-primary font-weight-bold w-75"
                onClick={handleLogout}
              >
                <span className="f-17">Logout</span>
                {loading && (
                  <span className="ml-3 spinner spinner-white mr-2"></span>
                )}
              </button>
            </div>
          </div>
        </>
      </Dropdown.Menu>
      {changePassword === true ? (
        <ChangePassword
          show={true}
          onHide={() => {
            setChangePassword(false)
          }}
        />
      ) : null}
    </Dropdown>
  )
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})

export default connect(mapStateToProps, { logoutUser })(UserProfileDropdown)
