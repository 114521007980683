import React, { useState } from 'react'
import { useFormik } from 'formik'

import { Link, Redirect } from 'react-router-dom'
import * as Yup from 'yup'
import { connect } from 'react-redux'
import { resetCode } from '../../../actions/authActions'

import { ReactComponent as Logo } from '../../../../_akhie_frontend/_assets/Logo.svg'
import APICallService from '../../../../api/apiCallService'
import apiJSON from '../../../../api/apiJSON'
import { FORGOTPASSWORD } from '../../../../utils/constants'
import { toast } from 'react-toastify'
import { String } from '../../../../utils/string'

const initialValues = {
  email: '',
}

const ForgotPassword = (props) => {
  const { intl } = props
  const [isRequested, setIsRequested] = useState(false)
  const [loading, setLoading] = useState(false)
  const ForgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Wrong email format')
      .min(3, 'Minimum 3 symbols')
      .max(50, 'Maximum 50 symbols')
      .required('Email is Required'),
  })

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return 'is-invalid'
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return 'is-valid'
    }

    return ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: ForgotPasswordSchema,
    onSubmit: async (values) => {
      setLoading(true)
      let apiService = new APICallService(
        FORGOTPASSWORD,
        apiJSON.ForgotPassword(values.email),
      )
      let response = await apiService.callAPI()
      if (response) {
        props.resetCode(response[0].code)
        toast.success(String.messages.forgotPassword)
        setIsRequested(true)
      }

      setLoading(false)
    },
  })

  return (
    <>
      {isRequested && <Redirect to="/auth" />}

      <div
        className="col-lg-4 bg-white mt-10 login-forgot"
        style={{ display: 'block' }}
      >
        <div className="mt-5 text-center">
          {/* <h3 className="font-size-h1 font-weight-bolder text-left text-54">
							<FormattedMessage id="AUTH.LOGIN.TITLE" />
						</h3> */}
          <Logo style={{ stroke: '#6959FC', height: '150px' }} />
        </div>
        <div className="mt-4 mb-3 d-flex justify-content-end border-top border-#ebedf3"></div>

        <div className="text-center mb-10 mb-lg-5">
          <h4 className="font-size-h4 font-weight-bold text-center text-54">
            {String.auth.forgotPassword}
          </h4>
          <div className="text-muted font-weight-bold">
            {String.auth.forgotPasswordText}
          </div>
        </div>
        <form
          onSubmit={formik.handleSubmit}
          className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        >
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}
          <div className="form-group fv-plugins-icon-container">
            <input
              type="email"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                'email',
              )}`}
              name="email"
              placeholder={String.placeHolder.email}
              {...formik.getFieldProps('email')}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>
          <div className="form-group d-flex flex-wrap flex-center mt-n3">
            <button
              id="kt_login_forgot_submit"
              type="submit"
              className="btn btn-primary font-weight-bold font-weight-bold px-9 py-4 my-3 mx-4"
              disabled={loading}
            >
              <span className="f-17">{String.general.submit}</span>
              {loading && <span className="ml-3 spinner spinner-white"></span>}
            </button>
          </div>
          <div className="text-center mt-n6 mb-4">
            <Link
              to="/auth/login"
              className="text-54 opacity9 font-weight-bold f-16 text-hover-primary my-3 mr-2"
              id="kt_login_forgot"
            >
              {String.auth.backToLogin}
            </Link>
          </div>
        </form>
      </div>
    </>
  )
}
const mapStateToProps = (state) => ({
  auth: state.auth,
  errors: state.errors,
})
export default connect(mapStateToProps, {
  resetCode,
})(ForgotPassword)
