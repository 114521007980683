import React, { Suspense } from 'react'
import { Redirect, Switch } from 'react-router-dom'
import ViewUsers from './ViewUsers'
import {
  LayoutSplashScreen,
  ContentRoute,
} from '../../../_akhie_frontend/layout'
export default function contactUs() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from eCommerce root URL to /customers */
          <Redirect exact={true} from="/users" to="/users/view-users/0" />
        }
        <ContentRoute path="/users/view-users/:key" component={ViewUsers} />
      </Switch>
    </Suspense>
  )
}
