import React, { useEffect, useState } from 'react'
import { toAbsoluteUrl } from '../../../_akhie_frontend/_helpers'
import { Card, Spinner } from 'react-bootstrap'
import APICallService from '../../../api/apiCallService'
import { POLICY } from '../../../utils/constants'

export const ErrorPage1=()=> {
  const [loading, setLoading] = useState(false)

  const [dashboardData, setDashboard] = useState()

  useEffect(() => {
    ;(async () => {
      setLoading(true)
    
      await fetchData()
      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const fetchData = async (pageNumber, searchTerm, sort) => {
    setLoading(true)
    let apiService = new APICallService(
      POLICY,
    )
    let response = await apiService.callAPI()
    let data = []
    if (response) {
     

      setDashboard(response)
    }

    setLoading(data)
  }

  return (
    <div className="d-flex flex-center flex-root ">
    <div
      className="d-flex justify-content-center" >
        

        <div className=''> 

      <Card style={{width:"100%"}}>
        
          {loading===false?<> <div dangerouslySetInnerHTML={{__html: dashboardData}}
></div></>:<><div className="d-flex justify-content-center align-items-center" style={{height:"100vh",width:"100vh"}}>
          <Spinner
            animation="border"
            variant="primary"
            style={{ height: '4em', width: '4em' }}
          />
        </div></>}
          
      
       
        
      </Card>
        </div>

    </div>
  </div>
  )
}
export default ErrorPage1
